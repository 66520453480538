<template>
  <div>
    <demandsHeader></demandsHeader>
    <div style="width: 60%; margin: 0 auto">
      <!-- 创新型中小企业自测-->
      <div v-if="id == 1">
        <h1 style="text-align: center">创新型中小企业自测</h1>

        <div style="clear: both" class="top">
          <div>
            <h2>1、基础条件</h2>
            <div class="title">
              （1）您的企业是否符合在中华人民共和国境内工商注册登记、具有独立法人资格，且符合《中小企业划型标准规定》?
              &ensp;<span style="color: #67c23a" v-if="ListOne.first == 0"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.first == 1">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="0" v-model="ListOne.first" /><span>
                A.是
              </span>
              <input type="radio" value="1" v-model="ListOne.first" /><span
                >B.否</span
              >
            </div>
            <div class="title">
              （2）企业是否满足未被列入经营异常名录或严重失信主体名单，提供的产品
              （服务）不属于国家禁止、限制或淘汰类？ &ensp;<span
                style="color: #67c23a"
                v-if="ListOne.two == 0"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.two == 1">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="0" v-model="ListOne.two" />
              <span> A.是 </span>
              <input type="radio" value="1" v-model="ListOne.two" />
              <span>B.否</span>
            </div>
            <div class="title">
              （3）企业是否满足近三年未发生重大安全（含网络安全、数据安全）、质量、环境污染等事故以及偷漏税等违法违规行为?
              &ensp;<span style="color: #67c23a" v-if="ListOne.three == 0"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.three == 1">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="0" v-model="ListOne.three" /><span>
                A.是
              </span>
              <input type="radio" value="1" v-model="ListOne.three" />
              <span>B.否</span>
            </div>
            <p
              class="top"
              style="color: red"
              v-if="
                ListOne.first == 1 || ListOne.two == 1 || ListOne.three == 1
              "
            >
              您在本环节已经不符合创新型中小企业判定标准（注：本环节需要全部符合条件）
            </p>
          </div>

          <div class="top">
            <span style="font-size: 1.25rem; font-weight: bold"
              >2、直通条件</span
            >&ensp;&ensp;&ensp;<span style="color: red"
              >友情提示：该环节只需满足一项即可直接符合“创新型中小企业”标准。如果均不符合，请直接进入评分环节自测。</span
            >
            <br />
            <div class="answer">
              <input
                type="checkbox"
                v-model="ListTwo"
                class="top"
                value="A"
              />A.近三年内获得过国家级、省级科技奖励。 <br />
              <input
                type="checkbox"
                v-model="ListTwo"
                class="top"
                value="B"
              />B.获得高新技术企业、国家级技术创新示范企业、知识产权优势企业和知识产权示范企业等荣誉（均为有效期内）。<br />
              <input
                type="checkbox"
                v-model="ListTwo"
                class="top"
                value="C"
              />C. 拥有经认定的省部级以上研发机构。 <br />
              <input
                type="checkbox"
                v-model="ListTwo"
                class="top"
                value="D"
              />D.近三年新增股权融资总额（合格机构投资者的实缴额）500万元以上。<br />
            </div>
          </div>

          <div class="top" v-if="ListTwo.length == 0">
            <hr style="border: 1px dashed #ccc; width: 100%; height: 1px" />
            <h2 style="text-align: center">----评分环节---</h2>
            <p style="text-align: center; color: red">
              友情提示：本环节要求评价得分达到
              60分以上（其中创新能力指标得分不低于
              20分、成长性指标及专业化指标得分均不低于
              15分）可认定为“创新型中小企业”
            </p>
            <div class="top">
              <h2>1、创新能力（满分40分）</h2>
              <div class="title">
                （1）与企业主导产品相关的有效知识产权数量（满分20分）
                &ensp;<span style="color: red" v-if="ListThree.first != null"
                  >+{{ ListThree.first }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="20" v-model="ListThree.first" /><span
                  >A.类高价值知识产权1项以上</span
                >
                <input type="radio" value="15" v-model="ListThree.first" /><span
                  >B.自主研发的Ⅰ类知识产权1项以上</span
                >
                <input type="radio" value="10" v-model="ListThree.first" /><span
                  >C.类知识产权1项以上</span
                >
                <input type="radio" value="5" v-model="ListThree.first" /><span
                  >D.Ⅱ类知识产权1项以上</span
                >
                <input type="radio" value="0" v-model="ListThree.first" /><span
                  >E.无</span
                >
              </div>
              <div class="title">
                （2）上年度研发费用总额占营业收入总额比重（满分20分）
                &ensp;<span style="color: red" v-if="ListThree.two != null"
                  >+{{ ListThree.two }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="20" v-model="ListThree.two" /><span
                  >A.5%以上</span
                >
                <input type="radio" value="15" v-model="ListThree.two" /><span
                  >B.3%-5%</span
                >
                <input type="radio" value="10" v-model="ListThree.two" /><span
                  >C.2%-3%</span
                >
                <input type="radio" value="5" v-model="ListThree.two" /><span
                  >D.1%-2%</span
                >
                <input type="radio" value="0" v-model="ListThree.two" /><span
                  >E. 1%以下</span
                >
              </div>
            </div>

            <div class="top">
              <h2>2、成长性（满分30分）</h2>
              <div class="title">
                （1）上年度主营业务收入增长率（满分20分） &ensp;<span
                  style="color: red"
                  v-if="ListThree.three != null"
                  >+{{ ListThree.three }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="20" v-model="ListThree.three" /><span
                  >A.15%以上</span
                >
                <input type="radio" value="15" v-model="ListThree.three" /><span
                  >B.10%-15%</span
                >
                <input type="radio" value="10" v-model="ListThree.three" /><span
                  >C.5%-10%</span
                >
                <input type="radio" value="5" v-model="ListThree.three" /><span
                  >D.0%-5%</span
                >
                <input type="radio" value="0" v-model="ListThree.three" /><span
                  >E.0%以下</span
                >
              </div>
              <div class="title">
                （2）上年度资产负债率（满分10分） &ensp;<span
                  style="color: red"
                  v-if="ListThree.four != null"
                  >+{{ ListThree.four }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="10" v-model="ListThree.four" /><span
                  >A.55%以下</span
                >
                <input type="radio" value="5" v-model="ListThree.four" /><span
                  >B.55%-75%</span
                >
                <input type="radio" value="0" v-model="ListThree.four" /><span
                  >C. 75%以上</span
                >
              </div>
            </div>

            <div class="top">
              <h2>3、专业化（满分30分）</h2>
              <div class="title">
                （1）主导产品所属领域情况（满分10分） &ensp;<span
                  style="color: red"
                  v-if="ListThree.five != null"
                  >+{{ ListThree.five }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="10" v-model="ListThree.five" /><span
                  >A.属于《战略性新兴产业分类</span
                >
                <input type="radio" value="5" v-model="ListThree.five" /><span
                  >B.属于其他领域</span
                >
              </div>
              <div class="title">
                （2）上年度主营业务收入总额占营业收入总额比重（满分 20分）
                &ensp;<span style="color: red" v-if="ListThree.six != null"
                  >+{{ ListThree.six }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="20" v-model="ListThree.six" /><span
                  >A.70%以上</span
                >
                <input type="radio" value="15" v-model="ListThree.six" /><span
                  >B.60%-70%</span
                >
                <input type="radio" value="10" v-model="ListThree.six" /><span
                  >C.55%-60%</span
                >
                <input type="radio" value="5" v-model="ListThree.six" /><span
                  >D.50%-55%</span
                >
                <input type="radio" value="0" v-model="ListThree.six" /><span
                  >E. 50%以下</span
                >
              </div>
            </div>
          </div>

          <div class="top" style="text-align: center; margin-bottom: 2rem">
            <el-button type="primary" @click="posttest"
              >提交并计算分值</el-button
            >
          </div>
        </div>
      </div>
      <!-- 专精特新中小企业自测 -->

      <div v-if="id == 2">
        <h1 style="text-align: center">专精特新中小企业自测</h1>

        <div style="clear: both" class="top">
          <div>
            <h2>1、基础条件</h2>
            <div class="title">
              （1）您的企业是否符合在中华人民共和国境内工商注册登记、具有独立法人资格，且符合《中小企业划型标准规定》?
              &ensp;<span style="color: #67c23a" v-if="ListOne.first == 0"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.first == 1">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="0" v-model="ListOne.first" /><span>
                A.是
              </span>
              <input type="radio" value="1" v-model="ListOne.first" /><span
                >B.否</span
              >
            </div>
            <div class="title">
              （2）企业是否满足未被列入经营异常名录或严重失信主体名单，提供的产品
              （服务）不属于国家禁止、限制或淘汰类？ &ensp;<span
                style="color: #67c23a"
                v-if="ListOne.two == 0"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.two == 1">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="0" v-model="ListOne.two" />
              <span> A.是 </span>
              <input type="radio" value="1" v-model="ListOne.two" />
              <span>B.否</span>
            </div>
            <div class="title">
              （3）企业是否满足近三年未发生重大安全（含网络安全、数据安全）、质量、环境污染等事故以及偷漏税等违法违规行为?
              &ensp;<span style="color: #67c23a" v-if="ListOne.three == 0"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.three == 1">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="0" v-model="ListOne.three" /><span>
                A.是
              </span>
              <input type="radio" value="1" v-model="ListOne.three" />
              <span>B.否</span>
            </div>
            <div class="title">
              （4）是否从事特定细分市场时间达到 2 年以上？ &ensp;<span
                style="color: #67c23a"
                v-if="ListOne.four == 0"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.four == 1">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="0" v-model="ListOne.four" /><span>
                A.是
              </span>
              <input type="radio" value="1" v-model="ListOne.four" /><span
                >B.否</span
              >
            </div>
            <div class="title">
              （5）上年度研发费用总额不低于 100 万元，且占营业收入总额比重不低于
              3%。 &ensp;<span style="color: #67c23a" v-if="ListOne.seven == 0"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.seven == 1">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="0" v-model="ListOne.seven" />
              <span> A.是 </span>
              <input type="radio" value="1" v-model="ListOne.seven" />
              <span>B.否</span>
            </div>
            <div class="title">
              （6）上年度营业收入总额在 1000 万元以上，或上年 度营业收入总额在
              1000 万元以下，但近 2
              年新增股权融资总额（合格机构投资者的实缴额）达到 2000
              万元以上。&ensp;<span
                style="color: #67c23a"
                v-if="ListOne.six == 0"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.six == 1">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="0" v-model="ListOne.six" /><span>
                A.是
              </span>
              <input type="radio" value="1" v-model="ListOne.six" />
              <span>B.否</span>
            </div>
            <p
              class="top"
              style="color: red"
              v-if="
                ListOne.first == 1 ||
                ListOne.two == 1 ||
                ListOne.three == 1 ||
                ListOne.four == 1 ||
                ListOne.seven == 1 ||
                ListOne.six == 1
              "
            >
              您在本环节已经不符合创新型中小企业判定标准（注：本环节需要全部符合条件）
            </p>
          </div>

          <div class="top">
            <span style="font-size: 1.25rem; font-weight: bold"
              >2、直通条件</span
            >
            <br />
            <div class="answer">
              <input
                type="checkbox"
                v-model="ListTwo"
                class="top"
                value="A"
              />A.
              近三年获得过省级科技奖励，并在获奖单位中排名前三；或获得国家级科技奖励，并在获奖单位中排名前五。
              <br />
              <input
                type="checkbox"
                v-model="ListTwo"
                class="top"
                value="B"
              />B. 近两年研发费用总额均值在1000万元以上。<br />
              <input
                type="checkbox"
                v-model="ListTwo"
                class="top"
                value="C"
              />C.
              近两年新增股权融资总额（合格机构投资者的实缴额）6000万元以上。<br />
              <input
                type="checkbox"
                v-model="ListTwo"
                class="top"
                value="D"
              />D.
              近三年进入“创客中国”中小企业创新创业大赛全国500强企业组名单。<br />
            </div>
          </div>

          <div class="top">
            <hr style="border: 1px dashed #ccc; width: 100%; height: 1px" />
            <h2 style="text-align: center">----评分环节---</h2>
            <p style="text-align: center; color: red">
              友情提示：该环节评价得分达到 60
              分以上方可满足“专精特新中小企业”申报条件。
            </p>
            <div class="top">
              <h2>1、专业化（满分25分）</h2>
              <div class="title">
                （1）上年度主营业务收入总额占营业收入总额比重（满分5分）
                &ensp;<span style="color: red" v-if="ListThree.first != null"
                  >+{{ ListThree.first }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="5" v-model="ListThree.first" /><span
                  >A. 80%以上</span
                >
                <input type="radio" value="3" v-model="ListThree.first" /><span
                  >B. 70%-80%</span
                >
                <input type="radio" value="1" v-model="ListThree.first" /><span
                  >C. 60%-70%</span
                >
                <input type="radio" value="0" v-model="ListThree.first" /><span
                  >D. 60%以下</span
                >
              </div>
              <div class="title">
                （2）近 2 年主营业务收入平均增长率（满分10分） &ensp;<span
                  style="color: red"
                  v-if="ListThree.two != null"
                  >+{{ ListThree.two }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="10" v-model="ListThree.two" /><span
                  >A. 10%以上</span
                >
                <input type="radio" value="8" v-model="ListThree.two" /><span
                  >B. 8%-10%</span
                >
                <input type="radio" value="6" v-model="ListThree.two" /><span
                  >C. 6%-8%</span
                >
                <input type="radio" value="4" v-model="ListThree.two" /><span
                  >D. 4%-6%</span
                >
                <input type="radio" value="2" v-model="ListThree.two" /><span
                  >E. 0%-4%</span
                >
                <input type="radio" value="0" v-model="ListThree.two" /><span
                  >F. 0%以下</span
                >
              </div>
              <div class="title">
                （3）从事特定细分市场年限（满分5分） &ensp;<span
                  style="color: red"
                  v-if="ListThree.three != null"
                  >+{{ ListThree.three }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="5" v-model="ListThree.three" /><span
                  >A. 10年及以上</span
                >
                <input type="radio" value="4" v-model="ListThree.three" /><span
                  >B. 8(含)-10年</span
                >
                <input type="radio" value="3" v-model="ListThree.three" /><span
                  >C. 6(含)-8年</span
                >
                <input type="radio" value="2" v-model="ListThree.three" /><span
                  >D. 4(含)-6年</span
                >
                <input type="radio" value="1" v-model="ListThree.three" /><span
                  >E. 2(含)-4年</span
                >
                <input type="radio" value="0" v-model="ListThree.three" /><span
                  >F. 2年以下</span
                >
              </div>
              <div class="title">
                （4）主导产品所属领域情况（满分5分） &ensp;<span
                  style="color: red"
                  v-if="ListThree.four != null"
                  >+{{ ListThree.four }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="5" v-model="ListThree.four" /><span
                  >A.
                  在产业链供应链关键环节及关键领域“补短板”“锻长板”“填空白”取得实际成效</span
                >
                <input type="radio" value="3" v-model="ListThree.four" /><span
                  >B.
                  属于工业“六基”领域、中华老字号名录或企业主导产品服务关键产业链重点龙头企业</span
                >
                <input type="radio" value="0" v-model="ListThree.four" /><span
                  >C. 不属于以上情况</span
                >
              </div>
            </div>

            <div class="top">
              <h2>2、精细化（满分25分）</h2>
              <div class="title">
                （1）数字化水平（满分5分） &ensp;<span
                  style="color: red"
                  v-if="ListThree.five != null"
                  >+{{ ListThree.five }}
                </span>
              </div>

              <div class="answer">
                <input type="radio" value="5" v-model="ListThree.five" /><span
                  >A. 三级以上</span
                >
                <input type="radio" value="3" v-model="ListThree.five" /><span
                  >B. 二级</span
                >
                <input type="radio" value="0" v-model="ListThree.five" /><span
                  >C. 一级</span
                >
              </div>
              <div class="title">
                (2)（质量管理水平（每满足一项加 3 分，最高不超过 5 分）
                &ensp;<span style="color: red" v-if="Listfour.length == 1"
                  >+3 </span
                ><span style="color: red" v-if="Listfour.length > 1">+5 </span>
              </div>
              <div class="answer">
                <input
                  type="checkbox"
                  v-model="Listfour"
                  class="top"
                  value="A"
                />A. 获得省级以上质量奖荣誉 <br />
                <input
                  type="checkbox"
                  v-model="Listfour"
                  class="top"
                  value="B"
                />B. 建立质量管理体系，获得 ISO9001 等质量管理体系认证证书<br />
                <input
                  type="checkbox"
                  v-model="Listfour"
                  class="top"
                  value="C"
                />C. 拥有自主品牌 <br />
                <input
                  type="checkbox"
                  v-model="Listfour"
                  class="top"
                  value="D"
                />D. 参与制修订标准<br />
              </div>

              <div class="title">
                （3）上年度净利润率（满分 10 分） &ensp;<span
                  style="color: red"
                  v-if="ListThree.six != null"
                  >+{{ ListThree.six }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="10" v-model="ListThree.six" /><span
                  >A. 10%以上</span
                >
                <input type="radio" value="8" v-model="ListThree.six" /><span
                  >B. 8%-10%</span
                >
                <input type="radio" value="6" v-model="ListThree.six" /><span
                  >C. 6%-8%</span
                >
                <input type="radio" value="4" v-model="ListThree.six" /><span
                  >D. 4%-6%</span
                >
                <input type="radio" value="2" v-model="ListThree.six" /><span
                  >E. 2%-4%</span
                >
                <input type="radio" value="0" v-model="ListThree.six" /><span
                  >F. 2%以下</span
                >
              </div>
              <div class="title">
                （4）上年度资产负债率（满分 5 分） &ensp;<span
                  style="color: red"
                  v-if="ListThree.seven != null"
                  >+{{ ListThree.seven }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="5" v-model="ListThree.seven" /><span
                  >A. 50%以下</span
                >
                <input type="radio" value="3" v-model="ListThree.seven" /><span
                  >B. 50%-60%</span
                >
                <input type="radio" value="1" v-model="ListThree.seven" /><span
                  >C. 60%-70%</span
                >
                <input type="radio" value="0" v-model="ListThree.seven" /><span
                  >D. 70%以上</span
                >
              </div>
            </div>

            <div class="top">
              <h2>3、地方特色（满分15分）</h2>
              <div class="title">
                （1）技术和产品有自身独特优势，主导产品在细分市场占有率和排名(满分6分)
                &ensp;<span style="color: red" v-if="ListThree.eight != null"
                  >+{{ ListThree.eight }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="6" v-model="ListThree.eight" /><span
                  >A. 全国细分市场占有率10%以上</span
                >
                <input type="radio" value="4" v-model="ListThree.eight" /><span
                  >B.
                  全国细分市场占有率5%-10%或排名前十名或进入龙头企业供应链体系</span
                >
                <input type="radio" value="2" v-model="ListThree.eight" /><span
                  >C. 全省细分市场占有率排名前三名</span
                >
                <input type="radio" value="0" v-model="ListThree.eight" /><span
                  >D. 不属于上述情况</span
                >
              </div>

              <div class="title">
                （2）企业和产品所属产业领域竞争优势(每满足一项按级别加分，满分9分)
                &ensp;<span style="color: red" v-if="ListThree.thirteen != 0"
                  >+{{ ListThree.thirteen }}</span
                >
              </div>
              <div class="answer">
                <input
                  type="checkbox"
                  v-model="Listfive"
                  class="top"
                  value="3"
                />A.
                符合制造强国战略十大重点产业领域或网络强国建设的信息基础设施、关键核心技术、网络安全、数据安全领域<br />
                <input
                  type="checkbox"
                  v-model="ListThree.fourteen"
                  class="top"
                  value="B"
                />B. 分布在国家级先进制造业集群或省 16 个先进制造业集群 &ensp;
                &ensp; &ensp;<input
                  type="radio"
                  value="3"
                  v-if="ListThree.fourteen != 0 && ListThree.fourteen != ''"
                  v-model="ListThree.fourteen"
                /><span
                  v-if="ListThree.fourteen != 0 && ListThree.fourteen != ''"
                  >A.国家级</span
                >
                <input
                  type="radio"
                  value="2"
                  v-model="ListThree.fourteen"
                  v-if="ListThree.fourteen != 0 && ListThree.fourteen != ''"
                /><span
                  v-if="ListThree.fourteen != 0 && ListThree.fourteen != ''"
                  >B.省级</span
                ><br />

                <input
                  type="checkbox"
                  v-model="ListThree.fifteen"
                  class="top"
                  value="C"
                />C. 拥有自主品牌 &ensp; &ensp; &ensp;
                <input
                  type="radio"
                  value="3"
                  v-if="ListThree.fifteen != 0 && ListThree.fifteen != ''"
                  v-model="ListThree.fifteen"
                /><span v-if="ListThree.fifteen != 0 && ListThree.fifteen != ''"
                  >A. 卓越产业链</span
                >
                <input
                  type="radio"
                  value="2"
                  v-model="ListThree.fifteen"
                  v-if="ListThree.fifteen != 0 && ListThree.fifteen != ''"
                /><span v-if="ListThree.fifteen != 0 && ListThree.fifteen != ''"
                  >B. 未来产业链</span
                >
                <input
                  type="radio"
                  value="two"
                  v-model="ListThree.fifteen"
                  v-if="ListThree.fifteen != 0 && ListThree.fifteen != ''"
                /><span v-if="ListThree.fifteen != 0 && ListThree.fifteen != ''"
                  >C. 优势产业链</span
                ><br />
              </div>
            </div>
            <div class="top">
              <h2>4、创新能力（满分35分）</h2>
              <div class="title">
                （1）与企业主导产品相关的有效知识产权数量（满分 10
                分）&ensp;<span
                  style="color: red"
                  v-if="ListThree.night != null"
                  >+{{ ListThree.night }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="10" v-model="ListThree.night" /><span
                  >A. Ⅰ类高价值知识产权1项以上</span
                >
                <input type="radio" value="8" v-model="ListThree.night" /><span
                  >B. 自主研发Ⅰ类知识产权1项以上</span
                >
                <input type="radio" value="6" v-model="ListThree.night" /><span
                  >C. Ⅰ类知识产权1项以上</span
                >
                <input type="radio" value="2" v-model="ListThree.night" /><span
                  >D. Ⅱ类知识产权1项以上</span
                >
                <input type="radio" value="0" v-model="ListThree.night" /><span
                  >E. 无</span
                >
              </div>

              <div class="title">
                （2）上年度研发费用投入（满分 10 分） &ensp;<span
                  style="color: red"
                  v-if="ListThree.ten != null"
                  >+{{ ListThree.ten }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="10" v-model="ListThree.ten" /><span
                  >A. 研发费用总额 500 万元以上或研发费用总额占营业
                  收入总额比重在 10%以上</span
                >
                <input type="radio" value="8" v-model="ListThree.ten" /><span
                  >B. 研发费用总额 400-500 万元或研发费用总额占营业
                  收入总额比重在 8%-10%</span
                >
                <input type="radio" value="6" v-model="ListThree.ten" /><span
                  >C. 研发费用总额 300-400
                  万元或研发费用总额占营业收入总额比重在 6%-8%</span
                >
                <input type="radio" value="4" v-model="ListThree.ten" /><span
                  >D. 研发费用总额 200-300
                  万元或研发费用总额占营业收入总额比重在 4%-6%</span
                >
                <input type="radio" value="2" v-model="ListThree.ten" /><span
                  >E. 研发费用总100-200万元或研发费用总额占营业收入总额比重在
                  3%-4%</span
                >
                <input type="radio" value="0" v-model="ListThree.ten" /><span
                  >F. 不属于以上情况</span
                >
              </div>

              <div class="title">
                （3）上年度研发人员占比（满分 5 分） &ensp;<span
                  style="color: red"
                  v-if="ListThree.eleven != null"
                  >+{{ ListThree.eleven }}
                </span>
              </div>
              <div class="answer">
                <input type="radio" value="5" v-model="ListThree.eleven" /><span
                  >A. 20%以上</span
                >
                <input type="radio" value="3" v-model="ListThree.eleven" /><span
                  >B. 10%-20%</span
                >
                <input type="radio" value="1" v-model="ListThree.eleven" /><span
                  >C. 5%-10%</span
                >
                <input type="radio" value="0" v-model="ListThree.eleven" /><span
                  >D. 5%以下</span
                >
              </div>

              <div class="title">
                （4）建立研发机构级别（满分 10 分）&ensp;<span
                  style="color: red"
                  v-if="ListThree.twelve != null"
                  >+{{ ListThree.twelve }}
                </span>
              </div>
              <div class="answer">
                <input
                  type="radio"
                  value="10"
                  v-model="ListThree.twelve"
                /><span>A. 国家级</span>
                <input type="radio" value="8" v-model="ListThree.twelve" /><span
                  >B. 省级</span
                >
                <input type="radio" value="4" v-model="ListThree.twelve" /><span
                  >C. 市级</span
                >
                <input type="radio" value="2" v-model="ListThree.twelve" /><span
                  >D. 市级以下</span
                >
                <input type="radio" value="0" v-model="ListThree.twelve" /><span
                  >E. 未建立研发机构</span
                >
              </div>
            </div>
          </div>

          <div class="top" style="text-align: center; margin-bottom: 2rem">
            <el-button type="primary" @click="posttest"
              >提交并计算分值</el-button
            >
          </div>
        </div>
      </div>

      <!-- 专精特新“小巨人”企业自测 -->

      <div v-if="id == 3">
        <h1 style="text-align: center">专精特新“小巨人”企业自测</h1>

        <div style="clear: both" class="top">
          <div>
            <h2>1、基础条件</h2>
            <div class="title">
              （1）您的企业是否符合在中华人民共和国境内工商注册登记、具有独立法人资格，且符合《中小企业划型标准规定》?
              &ensp;<span style="color: #67c23a" v-if="ListOne.first == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.first == 0">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.first" /><span>
                A.是
              </span>
              <input type="radio" value="0" v-model="ListOne.first" /><span
                >B.否</span
              >
            </div>
            <div class="title">
              （2）企业是否满足未被列入经营异常名录或严重失信主体名单，提供的产品
              （服务）不属于国家禁止、限制或淘汰类？ &ensp;<span
                style="color: #67c23a"
                v-if="ListOne.two == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.two == 0">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.two" />
              <span> A.是 </span>
              <input type="radio" value="0" v-model="ListOne.two" />
              <span>B.否</span>
            </div>
            <div class="title">
              （3）企业是否满足近三年未发生重大安全（含网络安全、数据安全）、质量、环境污染等事故以及偷漏税等违法违规行为?
              &ensp;<span style="color: #67c23a" v-if="ListOne.three == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.three == 0">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.three" /><span>
                A.是
              </span>
              <input type="radio" value="0" v-model="ListOne.three" />
              <span>B.否</span>
            </div>
            <p
              class="top"
              style="color: red"
              v-if="
                ListOne.first == 0 || ListOne.two == 0 || ListOne.three == 0
              "
            >
              您在本环节已经不符合专精特新“小巨人”企业判定标准（注：本环节需要全部符合条件）
            </p>
          </div>

          <div class="top">
            <h2>2、专业化</h2>
            <div class="title">
              1）截至上年末，企业是否从事特定细分市场时间达到 3 年以上？
              &ensp;<span style="color: #67c23a" v-if="ListOne.four == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.four == 0">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.four" /><span>
                A.是
              </span>
              <input type="radio" value="0" v-model="ListOne.four" /><span
                >B.否</span
              >
            </div>
            <div class="title">
              （2）截至上年末，主营业务收入总额占营业收入总额比重不低于 70%？
              &ensp;<span style="color: #67c23a" v-if="ListOne.five == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.five == 0">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.five" />
              <span> A.是 </span>
              <input type="radio" value="0" v-model="ListOne.five" />
              <span>B.否</span>
            </div>
            <div class="title">
              （3）是否近 2 年主营业务收入平均增长率不低于 5%？&ensp;<span
                style="color: #67c23a"
                v-if="ListOne.six == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.six == 0">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.six" /><span>
                A.是
              </span>
              <input type="radio" value="0" v-model="ListOne.six" />
              <span>B.否</span>
            </div>
            <p
              class="top"
              style="color: red"
              v-if="ListOne.four == 0 || ListOne.five == 0 || ListOne.six == 0"
            >
              您在本环节已经不符合专精特新“小巨人”企业判定标准（注：本环节需要全部符合条件）
            </p>
          </div>

          <div class="top">
            <h2>3、精细化</h2>
            <div class="title">
              （1）是否在研发设计、生产制造、供应链管理等环节，至少 1
              项核心业务采用信息系统支撑？ &ensp;<span
                style="color: #67c23a"
                v-if="ListOne.seven == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.seven == 0">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.seven" /><span>
                A.是
              </span>
              <input type="radio" value="0" v-model="ListOne.seven" /><span
                >B.否</span
              >
            </div>
            <div class="title">
              （2）是否取得相关管理体系认证，或产品通过发达国家和地区产品认证（国际标准协会行业认证）？
              &ensp;<span style="color: #67c23a" v-if="ListOne.eight == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.eight == 0">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.eight" />
              <span> A.是 </span>
              <input type="radio" value="0" v-model="ListOne.eight" />
              <span>B.否</span>
            </div>
            <div class="title">
              （3）截至上年末，企业资产负债率是否不高于 70%？ &ensp;<span
                style="color: #67c23a"
                v-if="ListOne.night == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.night == 0">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.night" /><span>
                A.是
              </span>
              <input type="radio" value="0" v-model="ListOne.night" />
              <span>B.否</span>
            </div>
            <p
              class="top"
              style="color: red"
              v-if="
                ListOne.seven == 0 || ListOne.eight == 0 || ListOne.night == 0
              "
            >
              您在本环节已经不符合创新型中小企业判定标准（注：本环节需要全部符合条件）
            </p>
          </div>
          <div class="top">
            <h2>4、特色化</h2>
            <div class="title">
              （1）技术和产品有自身独特优势，主导产品在全国细分市场占有率达到
              10%以上且享有较高知名度和影响力？ &ensp;<span
                style="color: #67c23a"
                v-if="ListOne.ten == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.ten == 0">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.ten" /><span>
                A.是
              </span>
              <input type="radio" value="0" v-model="ListOne.ten" /><span
                >B.否</span
              >
            </div>
            <div class="title">
              （2）是否拥有直接面向市场并具有竞争优势的自主品牌？ &ensp;<span
                style="color: #67c23a"
                v-if="ListOne.eleven == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.eleven == 0">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.eleven" />
              <span> A.是 </span>
              <input type="radio" value="0" v-model="ListOne.eleven" />
              <span>B.否</span>
            </div>
            <p
              class="top"
              style="color: red"
              v-if="ListOne.eleven == 0 || ListOne.ten == 0"
            >
              您在本环节已经不符合专精特新“小巨人”企业判定标准（注：本环节需要全部符合条件）
            </p>
          </div>

          <div class="top">
            <h2>5、创新能力</h2>
            <div class="title">
              一般性条件<br />
              （1）上年度营业额以及近两年研发费用占营业额收入比重： &ensp;<span
                style="color: #67c23a"
                v-if="ListOne.twelve != 0 && ListOne.twelve != null"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.twelve == 0">不符合 </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.twelve" /><span
                >A. 上年度营业收入总额在 1 亿元以上的企业，近 2
                年研发费用总额占营业收入总额比重均不低于 3% </span
              ><br />
              <input type="radio" value="2" v-model="ListOne.twelve" /><span
                >B. 上年度营业收入总额在 5000 万元—1 亿元的企业，近 2
                年研发费用总额占营业收入总额比重均不低于 6%</span
              ><br />
              <input type="radio" value="3" v-model="ListOne.twelve" /><span
                >C.
                上年度营业收入总额在5000万元以下的企业，同时满足近2年新增股权融资总额（合格机构投资者的实缴额）8000
                万元以上，且研发费用总额 3000 万元以上、研发
                人员占企业职工总数比重 50%以上</span
              ><br />
              <input type="radio" value="0" v-model="ListOne.twelve" /><span
                >D. 以上均不符合</span
              >
            </div>
            <div class="title">
              （2）是否自建或与高等院校、科研机构联合建立研发机构，设立技术研究院、企业技术中心、企业工程中心、院士专家工作站、博士后工作站等？
              &ensp;<span style="color: #67c23a" v-if="ListOne.thirteen == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.thirteen == 0"
                >不符合
              </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.thirteen" />
              <span> A.是 </span>
              <input type="radio" value="0" v-model="ListOne.thirteen" />
              <span>B.否</span>
            </div>
            <div class="title">
              （3）是否拥有 2
              项以上与主导产品相关的Ⅰ类知识产权，且实际应用并已产生经济效益？
              &ensp;<span style="color: #67c23a" v-if="ListOne.fourteen == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.fourteen == 0"
                >不符合
              </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.fourteen" /><span>
                A.是
              </span>
              <input type="radio" value="0" v-model="ListOne.fourteen" />
              <span>B.否</span>
            </div>

            <div class="title">
              创新直通条件<br />
              （1）是否符合近三年获得国家级科技奖励，并在获奖单位中排名前三？
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.fifteen" />
              <span> A.是 </span>
              <input type="radio" value="0" v-model="ListOne.fifteen" />
              <span>B.否</span>
            </div>
            <div class="title">
              （2）是否在近三年进入“创客中国”中小企业创新创业大赛全国 50
              强企业组名单？
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.sixteen" /><span>
                A.是
              </span>
              <input type="radio" value="0" v-model="ListOne.sixteen" />
              <span>B.否</span>
            </div>
          </div>

          <div class="top">
            <h2>6、产业配套</h2>
            <div class="title">
              （1）是否符合位于产业链关键环节，围绕重点产业链实现关键基础技术和产品的产业化应用，发挥“补短板”“锻长板”“填空白”
              等重要作用？ &ensp;<span
                style="color: #67c23a"
                v-if="ListOne.seventeen == 1"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.seventeen == 0"
                >不符合
              </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.seventeen" /><span>
                A.是
              </span>
              <input type="radio" value="0" v-model="ListOne.seventeen" /><span
                >B.否</span
              >
            </div>

            <p class="top" style="color: red" v-if="ListOne.seventeen == 0">
              您在本环节已经不符合专精特新“小巨人”企业判定标准（注：本环节需要全部符合条件）
            </p>
          </div>

          <div class="top">
            <h2>7、主导产品所属领域指标</h2>
            <div class="title">
              （1）企业所在的产业或细分技术领域： &ensp;<span
                style="color: #67c23a"
                v-if="ListOne.eighteen != 0 && ListOne.eighteen != null"
                >符合</span
              >
              <span style="color: red" v-if="ListOne.eighteen == 0"
                >不符合
              </span>
            </div>
            <div class="answer">
              <input type="radio" value="1" v-model="ListOne.eighteen" /><span
                >A.
                从事细分产品市场属于制造业核心基础零部件、元器件、关键软件、先进基础工艺、关键基础材料和产业技术基础
              </span>
              <br />
              <input type="radio" value="2" v-model="ListOne.eighteen" /><span
                >B.符合制造强国战略十大重点产业领域</span
              >
              <br />
              <input type="radio" value="3" v-model="ListOne.eighteen" /><span
                >C.属于网络强国建设的信息基础设施、关键核心技术、网络安全、数据安全领域等产品 </span
              ><br />
              <input type="radio" value="0" v-model="ListOne.eighteen" /><span
                >D.其他</span
              >
            </div>
            <p
              class="top"
              style="color: red"
              v-if="
                ListOne.first == 0 || ListOne.two == 0 || ListOne.three == 0
              "
            >
              您在本环节已经不符合创新型中小企业判定标准（注：本环节需要全部符合条件）
            </p>
          </div>

          <div class="top" style="text-align: center; margin-bottom: 2rem">
            <el-button type="primary" @click="posttest">
              提交并计算分值
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="计算中..."
      :visible.sync="dialogTableVisible1"
      @open="openDialog1"
      width="540px"
    >
      <div style="width: 400px; height: 400px; margin: 0 auto">
        <img @dragstart.prevent
          style="width: 400px; height: 400px"
          src="../../images/testing.gif"
        />
      </div>
      <div style="text-align: center">
        <span>系统正在智能匹配检测，请稍侯...</span>
      </div>
    </el-dialog>

    <el-dialog
      title="测评结果"
      :visible.sync="dialogTableVisible2"
      @open="handleDialogOpen"
      @close="handleDialogClose"
      width="670px"
    >
      <div v-if="(id == 2 && ListTwo.length > 0) || ListTwo.length <= 0">
        <div
          ref="radarChart"
          style="width: 300px; height: 300px; margin: 0 auto"
        ></div>
        <div
          v-if="ListTwo.length > 0"
          style="text-align: center; font-size: 18px; color: red"
        >
          <span style="margin: 0; color: red; font-size: 18px">您的企业满足直通条件</span>
        </div>
        <div
          style="
            margin: 10px;
            text-align: center;
            font-size: 30px;
            color: black;
            font-weight: bold;
          "
        >
          <div v-if="id != 3">
            <span style="margin: 0">总评分数</span>
            <span :class="[totalColor ? 'green' : 'red']">
              {{ totalPoints }}
            </span>
            <span>分</span>
          </div>
        </div>
        <div
          v-if="id == 1"
          style="text-align: left; line-height: 2; font-size: 16px"
        >
          <span style="margin: 0">其中，创新能力指标得分：</span>
          <span :class="[ListThree.innovateColor ? 'green' : 'red']"
            >{{ chartData1.seriesData[0].data[0].value[0] }}分</span
          >
          <span style="margin: 0">；成长性指标得分：</span>
          <span :class="[ListThree.growUpColor ? 'green' : 'red']"
            >{{ chartData1.seriesData[0].data[0].value[1] }}分</span
          >
          <span style="margin: 0">；专业化指标得分：</span>
          <span :class="[ListThree.professionColor ? 'green' : 'red']"
            >{{ chartData1.seriesData[0].data[0].value[2] }}分</span
          >
        </div>
        <div style="text-align: center; font-size: 18px">
          <span style="margin: 0">根据评分，您的企业</span>
          <span style="margin: 0">{{ isPass }}</span>
          <span style="margin: 0">{{ companyType }}</span>
          <span style="margin: 0">评价标准</span>
        </div>
        <el-table
          :border="true"
          :data="suggestData"
          v-if="suggestData.length > 0"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50px"
          ></el-table-column>
          <el-table-column property="suggest" label="建议"></el-table-column>
        </el-table>
      </div>
      <div v-else style="text-align: center; font-size: 18px">
        <span style="margin: 0"
          >根据评分，您的企业符合创新型中小企业评价标准。</span
        >
      </div>
      <div style="text-align: center; margin-top: 20px">
        <span style="margin: 0px; color: red; font-size: 18px"
          >- 匹配自测结果仅供您进行参考，不作为项目申报依据，具体以申报认定为准
          -</span
        >
      </div>
    </el-dialog>
    
      <div class="suspend" @click="openDialog3">
        <div class="item">
          <img @dragstart.prevent src="@/img/index/customer-service.png" />
          <div>智能客服</div>
        </div>
      </div>
    <siteFooter></siteFooter>
      <el-dialog
        style="padding: 0"
        :visible.sync="dialogTableVisible3"
        :modal-append-to-body="false"
        width="1200px"
      >
        <custService></custService>
      </el-dialog>
  </div>
</template>

<script>
import demandsHeader from "@/components/demandsHeader";
import custService from "@/view/custService/custService";
import siteFooter from "@/components/siteFooter";
import * as echarts from "echarts";
export default {
  components: {
    demandsHeader: demandsHeader,
    custService: custService,
    siteFooter: siteFooter,
  },
  data() {
    return {
      id: null,
      name: "",
      dialogTableVisible3: false,
      ListOne: {
        first: null,
        two: null,
        three: null,
        four: null,
        five: null,
        six: null,
        seven: null,
        eight: null,
        night: null,
        ten: null,
        eleven: null,
        twelve: null,
        thirteen: null,
        fourteen: null,
        fifteen: null,
        sixteen: null,
        seventeen: null,
        eighteen: null,
        nineteen: null,
        twenty: null,
      },
      ListTwo: [],
      ListThree: {
        first: null,
        two: null,
        three: null,
        four: null,
        five: null,
        six: null,
        seven: null,
        eight: null,
        night: null,
        ten: null,
        eleven: null,
        twelve: null,
        thirteen: 0,
        fourteen: 0,
        fifteen: 0,
        innovateColor: null,
        growUpColor: null,
        professionColor: null,
      },
      Listfour: [],
      Listfive: [],
      chartData1: {
        // 这里放置雷达图的数据
        indicatorData: [
          {
            name: "创新性",
            max: 40,
          },
          {
            name: "专业化",
            max: 30,
          },
          {
            name: "成长性",
            max: 30,
          },
        ],
        seriesData: [
          {
            type: "radar",
            data: [
              {
                value: [0, 0, 0],
                areaStyle: {
                  color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                    {
                      color: "blue",
                      offset: 0,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      },
      chartData2: {
        // 这里放置雷达图的数据
        indicatorData: [
          {
            name: "专",
            max: 25,
          },
          {
            name: "精",
            max: 25,
          },
          {
            name: "特",
            max: 15,
          },
          {
            name: "新",
            max: 35,
          },
        ],
        seriesData: [
          {
            type: "radar",
            data: [
              {
                value: [0, 0, 0, 0],
                areaStyle: {
                  color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                    {
                      color: "blue",
                      offset: 0,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      },
      chartData3: {
        // 这里放置雷达图的数据
        indicatorData: [
          {
            name: "专",
            max: 3,
          },
          {
            name: "精",
            max: 3,
          },
          {
            name: "特",
            max: 2,
          },
          {
            name: "新",
            max: 5,
          },
          {
            name: "链",
            max: 1,
          },
          {
            name: "品",
            max: 1,
          },
        ],
        seriesData: [
          {
            type: "radar",
            data: [
              {
                value: [0, 0, 0, 0, 0, 0, 0],
                areaStyle: {
                  color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                    {
                      color: "blue",
                      offset: 0,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      },
      suggestData: [],
      dialogTableVisible1: false,
      dialogTableVisible2: false,
      totalPoints: NaN,
      isPass: "",
      companyType: "",
      totalColor: null,
    };
  },
  created() {
    this.id = this.$route.query.id;
    // console.log(this.id);
  },
  watch: {
    "ListThree.fourteen"(newValue, oldValue) {
      this.ListThree.thirteen = 0;
      if (this.Listfive.length > 0) {
        this.ListThree.thirteen = this.ListThree.thirteen + 3;
      }
      if (this.ListThree.fifteen != true && this.ListThree.fifteen != false) {
        if (this.ListThree.fifteen == "two") {
          this.ListThree.thirteen = this.ListThree.thirteen + 2;
        } else {
          this.ListThree.thirteen =
            this.ListThree.thirteen + parseInt(this.ListThree.fifteen);
        }
      }
      if (this.ListThree.fourteen != true && this.ListThree.fourteen != false) {
        this.ListThree.thirteen =
          this.ListThree.thirteen + parseInt(this.ListThree.fourteen);
      }
    },
    "ListThree.fifteen"(newValue, oldValue) {
      this.ListThree.thirteen = 0;
      if (this.Listfive.length > 0) {
        this.ListThree.thirteen = this.ListThree.thirteen + 3;
      }
      if (this.ListThree.fifteen != true && this.ListThree.fifteen != false) {
        if (this.ListThree.fifteen == "two") {
          this.ListThree.thirteen = this.ListThree.thirteen + 2;
        } else {
          this.ListThree.thirteen =
            this.ListThree.thirteen + parseInt(this.ListThree.fifteen);
        }
      }
      if (this.ListThree.fourteen != true && this.ListThree.fourteen != false) {
        this.ListThree.thirteen =
          this.ListThree.thirteen + parseInt(this.ListThree.fourteen);
      }
    },
    Listfive(newValue, oldValue) {
      this.ListThree.thirteen = 0;
      if (this.Listfive.length > 0) {
        this.ListThree.thirteen = this.ListThree.thirteen + 3;
      }
      if (this.ListThree.fifteen != true && this.ListThree.fifteen != false) {
        if (this.ListThree.fifteen == "two") {
          this.ListThree.thirteen = this.ListThree.thirteen + 2;
        } else {
          this.ListThree.thirteen =
            this.ListThree.thirteen + parseInt(this.ListThree.fifteen);
        }
      }
      if (this.ListThree.fourteen != true && this.ListThree.fourteen != false) {
        this.ListThree.thirteen =
          this.ListThree.thirteen + parseInt(this.ListThree.fourteen);
      }
    },
  },

  methods: {
    openDialog3() {
      this.dialogTableVisible3 = true;
    },
    posttest() {
      if (this.id == 1) {
        if (
          !(
            this.ListOne.first == 0 &&
            this.ListOne.two == 0 &&
            this.ListOne.three == 0 &&
            (this.ListTwo.length != 0 ||
              (this.ListThree.first != null &&
                this.ListThree.two != null &&
                this.ListThree.three != null &&
                this.ListThree.four != null &&
                this.ListThree.five != null &&
                this.ListThree.six != null))
          )
        ) {
          this.$message({
            message: "表单未填写完整或必要条件不符合，请检查",
            type: "warning",
          });
          return;
        }
        this.dialogTableVisible1 = true;
      } else if (this.id == 2) {
        if (
          !(
            this.ListOne.first == 0 &&
            this.ListOne.two == 0 &&
            this.ListOne.three == 0 &&
            this.ListOne.four == 0 &&
            this.ListOne.seven == 0 &&
            this.ListOne.six == 0 &&
            this.ListThree.first != null &&
            this.ListThree.two != null &&
            this.ListThree.three != null &&
            this.ListThree.four != null &&
            this.ListThree.five != null &&
            this.ListThree.six != null &&
            this.ListThree.seven != null &&
            this.ListThree.eight != null &&
            this.ListThree.night != null &&
            this.ListThree.ten != null &&
            this.ListThree.eleven != null &&
            this.ListThree.twelve != null &&
            this.ListThree.thirteen != 0
          )
        ) {
          this.$message({
            message: "表单未填写完整或必要条件不符合，请检查",
            type: "warning",
          });
          return;
        }
        this.dialogTableVisible1 = true;
      } else if (this.id == 3) {
        if (
          !(
            this.ListOne.first == 1 &&
            this.ListOne.two == 1 &&
            this.ListOne.three == 1 &&
            this.ListOne.four == 1 &&
            this.ListOne.five == 1 &&
            this.ListOne.six == 1 &&
            this.ListOne.seven == 1 &&
            this.ListOne.eight == 1 &&
            this.ListOne.night == 1 &&
            this.ListOne.ten == 1 &&
            this.ListOne.eleven == 1 &&
            this.ListOne.twelve != null &&
            this.ListOne.twelve != 0 &&
            this.ListOne.thirteen == 1 &&
            this.ListOne.fourteen == 1 &&
            this.ListOne.fifteen != null &&
            this.ListOne.sixteen != null &&
            this.ListOne.seventeen == 1 &&
            this.ListOne.eighteen != null &&
            this.ListOne.eighteen != 0
          )
        ) {
          this.$message({
            message: "表单未填写完整或必要条件不符合，请检查",
            type: "warning",
          });
          return;
        }
        this.dialogTableVisible1 = true;
      }
    },
    openDialog1() {
      this.suggestData = [];
      if (this.id == 1) {
        this.chartData1.seriesData[0].data[0].value[0] =
          parseInt(this.ListThree.first) + parseInt(this.ListThree.two);
        this.chartData1.seriesData[0].data[0].value[1] =
          parseInt(this.ListThree.three) + parseInt(this.ListThree.four);
        this.chartData1.seriesData[0].data[0].value[2] =
          parseInt(this.ListThree.five) + parseInt(this.ListThree.six);

        this.totalPoints =
          this.chartData1.seriesData[0].data[0].value[0] +
          this.chartData1.seriesData[0].data[0].value[1] +
          this.chartData1.seriesData[0].data[0].value[2];
        this.companyType = "创新型中小企业";
        if (
          this.totalPoints >= 60 &&
          this.chartData1.seriesData[0].data[0].value[0] >= 20 &&
          this.chartData1.seriesData[0].data[0].value[1] >= 15 &&
          this.chartData1.seriesData[0].data[0].value[2] >= 15
        ) {
          this.isPass = "符合";
          this.totalColor = true;
        } else {
          this.isPass = "暂不符合";
          this.totalColor = false;
        }

        if (this.totalPoints < 60) {
          this.suggestData.push({
            suggest: "	您的企业总评分应该大于或等于60分",
          });
        }

        if (this.chartData1.seriesData[0].data[0].value[0] >= 20) {
          this.ListThree.innovateColor = true;
        } else {
          this.ListThree.innovateColor = false;
          this.suggestData.push({
            suggest: "	您的企业创新能力评分应该大于或等于20分",
          });
        }

        if (this.chartData1.seriesData[0].data[0].value[1] >= 15) {
          this.ListThree.growUpColor = true;
        } else {
          this.ListThree.growUpColor = false;
          this.suggestData.push({
            suggest: "	您的企业成长性评分应该大于或等于15分",
          });
        }

        if (this.chartData1.seriesData[0].data[0].value[2] >= 15) {
          this.ListThree.professionColor = true;
        } else {
          this.ListThree.professionColor = false;
          this.suggestData.push({
            suggest: "	您的企业专业化评分应该大于或等于15分",
          });
        }
      } else if (this.id == 2) {
        this.chartData2.seriesData[0].data[0].value[0] =
          parseInt(this.ListThree.first) +
          parseInt(this.ListThree.two) +
          parseInt(this.ListThree.three) +
          parseInt(this.ListThree.four);
        var score;
        if (this.Listfour.length <= 0) {
          score = 0;
        } else if (this.Listfour.length >= 2) {
          score = 5;
        } else {
          score = 3;
        }

        this.chartData2.seriesData[0].data[0].value[1] =
          parseInt(this.ListThree.five) +
          score +
          parseInt(this.ListThree.six) +
          parseInt(this.ListThree.seven);

        this.chartData2.seriesData[0].data[0].value[2] =
          parseInt(this.ListThree.eight) + parseInt(this.ListThree.thirteen);

        this.chartData2.seriesData[0].data[0].value[3] =
          parseInt(this.ListThree.night) +
          parseInt(this.ListThree.ten) +
          parseInt(this.ListThree.eleven) +
          parseInt(this.ListThree.twelve);
        this.totalPoints =
          this.chartData2.seriesData[0].data[0].value[0] +
          this.chartData2.seriesData[0].data[0].value[1] +
          this.chartData2.seriesData[0].data[0].value[2] +
          this.chartData2.seriesData[0].data[0].value[3];
        this.companyType = "专精特新中小企业";

        if (this.totalPoints >= 60) {
          this.isPass = "符合";
          this.totalColor = true;
        } else {
          this.isPass = "暂不符合";
          this.totalColor = false;
        }

        if (this.totalPoints < 60) {
          this.suggestData.push({
            suggest: "	您的企业总评分应该大于或等于60分",
          });
        }
      } else if (this.id == 3) {
        this.chartData3.seriesData[0].data[0].value[0] =
          parseInt(this.ListOne.four) +
          parseInt(this.ListOne.five) +
          parseInt(this.ListOne.six);

        this.chartData3.seriesData[0].data[0].value[1] =
          parseInt(this.ListOne.seven) +
          parseInt(this.ListOne.eight) +
          parseInt(this.ListOne.night);

        this.chartData3.seriesData[0].data[0].value[2] =
          parseInt(this.ListOne.ten) + parseInt(this.ListOne.eleven);

        if (parseInt(this.ListOne.twelve) != 0) {
          this.chartData3.seriesData[0].data[0].value[3] = 1;
        }

        this.chartData3.seriesData[0].data[0].value[3] +=
          parseInt(this.ListOne.thirteen) +
          parseInt(this.ListOne.fourteen) +
          parseInt(this.ListOne.fifteen) +
          parseInt(this.ListOne.sixteen);

        this.chartData3.seriesData[0].data[0].value[4] = parseInt(
          this.ListOne.seventeen
        );

        if (parseInt(this.ListOne.eighteen) != 0) {
          this.chartData3.seriesData[0].data[0].value[5] = 1;
        }

        this.companyType = '专精特新"小巨人"企业';
        this.isPass = "符合";
        this.totalColor = true;
      }

      setTimeout(() => {
        this.dialogTableVisible1 = false;
        this.dialogTableVisible2 = true;
      }, 2000);
    },

    handleDialogOpen() {
      this.$nextTick(() => {
        // 等待下一个 DOM 更新周期确保 el-dialog 可见后再初始化雷达图
        if (
          this.ListTwo.length <= 0 ||
          (this.id == 2 && this.ListTwo.length > 0)
        ) {
          const chartContainer = this.$refs.radarChart;
          const myChart = echarts.init(chartContainer);
          var indicatorData, seriesData;
          if (this.id == 1) {
            ({ indicatorData, seriesData } = this.chartData1);
          } else if (this.id == 2) {
            ({ indicatorData, seriesData } = this.chartData2);
          } else if (this.id == 3) {
            ({ indicatorData, seriesData } = this.chartData3);
          } else {
            return;
          }

          myChart.setOption({
            radar: {
              indicator: indicatorData,
            },
            series: seriesData,
          });
        }
      });
    },
    handleDialogClose() {
      if (this.ListTwo.length <= 0) {
        const chartContainer = this.$refs.radarChart;
        const myChart = echarts.init(chartContainer);
        myChart.dispose();
      }
    },
  },
};
</script>

<style scoped>
.green {
  color: #008800;
  margin: 0;
}

.red {
  color: #ff0000;
  margin: 0;
}

.title {
  margin-top: 2rem;
}

.answer {
  margin-top: 1rem;
  margin-left: 2.125rem;
}

.top {
  margin-top: 2rem;
}

input:checked + span {
  color: #028dfb;
  font-weight: bold;
}

span {
  margin-right: 2rem;
}

button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 0.0625rem solid #dcdfe6;
  border-color: #dcdfe6;
  color: white;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  background-color: #028dfb;
}
.suspend {
  position: fixed;
  right: 1.125rem;
  bottom: 8.125rem;
  width: 5.3125rem;
  background: #ffffff;
  border-radius: 0.4375rem;
  padding: 1.25rem 0;
  z-index: 10;
}

.suspend .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suspend .item img {
  width: 1.75rem;
}

.suspend .item div {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.3125rem;
}
</style>
