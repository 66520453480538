import { render, staticRenderFns } from "./demandsAdd.vue?vue&type=template&id=45d0f4be&scoped=true"
import script from "./demandsAdd.vue?vue&type=script&lang=js"
export * from "./demandsAdd.vue?vue&type=script&lang=js"
import style0 from "./demandsAdd.vue?vue&type=style&index=0&id=45d0f4be&prod&scoped=true&lang=css"
import style1 from "./demandsAdd.vue?vue&type=style&index=1&id=45d0f4be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d0f4be",
  null
  
)

export default component.exports