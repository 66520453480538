<template>
  <div class="bb">
    <div class="background"></div>
    <div class="bj">
      <el-form ref="form" :rules="rules" :model="loginForm">
        <el-form-item label="用户名" class="login" prop="username">
          <el-input v-model="loginForm.username" placeholder="请输入用户名" @blur="userblur"></el-input>
        </el-form-item>
        <el-form-item label="密码" class="login" prop="password">
          <el-input show-password v-model="loginForm.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="验证码" class="login" prop="CheckCode">
          <el-input v-model="loginForm.CheckCode" placeholder="请输入验证码"></el-input>
          <input type="button" v-model="code"
            style="width: 27%; border-color: #DCDFE6; border-radius: 0px 4px 4px 0px; height: 41px;position: absolute;right: -1px;bottom: -1px;"
            title="点击更换验证码" />
        </el-form-item>
        <div class="login" style="margin-top: 20px;">
          <el-button @click="handleLogin" :disabled="isdisabled" id="LOGIN"
            v-on:keyup.13.native="handleLogin">登录</el-button>
        </div>
      </el-form>
      <div class="login">
        <a style="margin-top: 10px; font-size: 12px;float: left; color: #0ba1fe;cursor: pointer;"
          @click="OtherLogin"><img @dragstart.prevent src="../images/jszw_logo.png" width="20px" alt=""><span
            style="font-size: 12px;">江苏政务服务网账号登录</span></a>
        <div style="margin-top: 10px;float: right; text-align: right; font-size: 12px;color: #0ba1fe;">
          <div style="cursor: pointer" @click="register">没有账号?去注册!</div>
          <div style="margin-top: 10px;cursor: pointer" @click="indexControls">找回密码</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "../api/request";

export default {
  data() {
    // const codevalue = (rule, value, callback) => {
    //   if (this.inputCode.toUpperCase() === "" || this.inputCode.toUpperCase() === null) {
    //     callback(new Error('请输入验证码'))

    //   } else if (this.inputCode.toUpperCase() != this.code.toUpperCase()) {
    //     callback(new Error('请输入正确的验证码'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      loginForm: {
        username: "",
        password: "",
        CheckCode: "",
      },
      captchaInput: "",
      captchaSrc: "",
      code: "----",
      isdisabled: false,
      rules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        CheckCode: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }],
      },
    };
  },
  created() {
    this.getuseridbyuuid();
    if (this.loginForm.username) {
      request
        .post("/api/Login/GetCheckCode", { keyword: this.loginForm.username })
        .then((res) => {
          // console.log(res)
          this.code = res.data.Data
        })
        .catch((error) => {
          // 请求失败，处理错误
          console.error("Error fetching data:", error);
        });
    } else {
      this.code = "----";
    }
    //    this.setLogin();
    //按回车键响应
    // var _this = this;
    // console.log(document);
    // console.log(_this);
    // document.getElementById('#LOGIN').onkeydown = function (e) {
    //   let key = window.event.keyCode;
    //   if (key == 13) {
    //     _this.handleLogin();
    //   }
    // };
  },
  mounted() {
    // 初始化验证码
    //request.default.withCredentials = true;
    // this.createCode();
  },
  methods: {
    getuseridbyuuid() {
      let url = "/api/Jszwfw/GetUserByUuid";
      let reqObj = {
        keyword: this.$route.params.uuid
      };
      // console.log(reqObj);
      request.post(url, reqObj).then((res) => {
        // console.log(res);
        if (res.data.Status == 1) {
          if (res.data.Data.UserType == 1) {
            localStorage.setItem("USERTYPE", "USER");
            localStorage.setItem("USERID", res.data.Data.User.id);
            localStorage.setItem("COMPANYNAME", res.data.Data.User.nick_name);
            localStorage.setItem("LOGINNAME", res.data.Data.User.nick_name);
            localStorage.setItem("QYID", res.data.Data.User.qyid);
            localStorage.setItem("TOKEN", res.data.Data.Token);
          } else if (res.data.Data.UserType == 2) {
            localStorage.setItem("USERTYPE", "ENTERPRISE");
            localStorage.setItem("USERID", res.data.Data.Qy.id);
            localStorage.setItem("COMPANYNAME", res.data.Data.Qy.name);
            localStorage.setItem("QYID", res.data.Data.Qy.id);
            localStorage.setItem("TOKEN", res.data.Data.Token);
          }
          this.$router.push("/");
        }
      });
    },
    userblur() {
      if (this.loginForm.username) {
        request
          .post("/api/Login/GetCheckCode", { keyword: this.loginForm.username })
          .then((res) => {
            // console.log(res)
            this.code = res.data.Data
          })
          .catch((error) => {
            // 请求失败，处理错误
            console.error("Error fetching data:", error);
          });
      } else {
        this.code = "----";
      }
    },
    OtherLogin() {
      let url = 'https://nts.jszwfw.gov.cn:9087/ntzwdt?appid=f3b4727b-7f58-4eff-8735-b5a657c49563'
      window.open(url, '_blank')
    },
    register() {
      this.$router.push("/register");
    },
    indexControls() {
      this.$router.push("/RetrievePassword");
    },
    //在全局定义验证码
    open1(string) {
      this.$message(string);
    },
    open2(string) {
      this.$message({
        message: string,
        type: "success",
      });
    },

    open3(string) {
      this.$message({
        message: string,
        type: "warning",
      });
    },

    open4(string) {
      this.$message.error(string);
    },
    createCode() {
      this.code = "";
      var codeLength = 4; //验证码的长度
      var random = new Array(
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ); //随机数
      for (var i = 0; i < codeLength; i++) {
        //循环操作
        var index = Math.floor(Math.random() * 36); //取得随机数的索引（0~35）
        this.code += random[index]; //根据索引取得随机数加到code上
      }
    },
    // 处理登录
    handleLogin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isdisabled = true;
          request
            .post("/api/login/login", this.loginForm)
            .then((res) => {
              // console.log(res)
              // 请求成功，更新items数据
              if (res.data.Status == 0) {
                this.open3(res.data.Msg);
                this.isdisabled = false;
              } else {
                localStorage.setItem("USERID", res.data.Data.data.id);
                localStorage.setItem("COMPANYNAME", res.data.Data.name);
                localStorage.setItem("LOGINNAME", res.data.Data.data.user_name);
                localStorage.setItem("USERTYPE", res.data.Msg);
                localStorage.setItem("TOKEN", res.data.Data.token);
                if (res.data.Msg == "USER") {
                  localStorage.setItem("QYID", res.data.Data.data.qyid);
                } else {
                  localStorage.setItem("QYID", res.data.Data.data.id);
                }
                this.open2("登录成功");
                this.isdisabled = false;
                this.$router.push("/");
              }
            })
            .catch((error) => {
              // 请求失败，处理错误
              console.error("Error fetching data:", error);
            });
        }
      })
    },
  },
};
</script>

<style scoped>
.bb {
  position: relative;
  width: 100%;
  height: 97vh;
  /* 设置高度为浏览器窗口高度 */
  overflow: hidden;
  /* 防止内容溢出 */
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/eqitong/login-bj.png");
  background-size: cover;
  background-position: center;
}

.bj {
  width: 480px;
  height: 400px;
  background-color: white;
  position: absolute;
  top: 20%;
  right: 2%;
  border-radius: 3rem;
}

.login {
  margin: 10px auto;
  width: 400px;
}

span {
  font-size: 16px;
}

input {
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  margin-top: 3px;
  border: 1px solid gray;
}

button {
  width: 102%;
  height: 32px;
  background-color: #028dfc;
  color: white;
  border: 0;
}

a {
  text-decoration: none;
  color: black;
  float: right;
  font-size: 16px;
  margin-top: 8px;
  margin-right: -15px;
}
</style>