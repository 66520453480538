<template>
  <div>
    <el-dialog title="提示" :visible.sync="isshow" width="30%">
      <span>还未登录，请先登录系统</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isshow = false" style="line-height: 40px;padding: 0px 20px;">取 消</el-button>
        <el-button
          type="primary"
          @click="isshow = false"
          style="line-height: 40px;padding: 0px 20px;"
        >
          确
          定
        </el-button>
      </span>
    </el-dialog>
    <div class="content">
      <div class="policy-info-detail-page__main__top">
        <div style="border-bottom: 0.0625rem solid #eee;">
          <div class="policy-info-detail-page__main__top__title">
            <span class="m-l-10 m-r-10">
              {{ ProjectInfo.title
              }}
            </span>
          </div>
          <div class="el-row">
            <div class="el-col el-col-2">&nbsp;</div>
            <div class="flex el-col">
              <i class="blue-round-icon"></i>
              <div class="field-item__line">项目标签：</div>
              <div class="field-item__value">{{ ProjectInfo.Lables }}</div>
            </div>
            <div class="el-col el-col-2">&nbsp;</div>
            <div class="flex el-col">
              <i class="blue-round-icon"></i>
              <div class="field-item__line">扶持金额：</div>
              <div class="field-item__value">{{ ProjectInfo.Support }}</div>
            </div>
            <div class="el-col el-col-2">&nbsp;</div>
            <div class="flex el-col">
              <i class="blue-round-icon"></i>
              <div class="field-item__line">关联政策：</div>
              <div class="field-item__value">{{ ProjectInfo.Policy }}</div>
            </div>
          </div>
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col">
            <i class="blue-round-icon"></i>
            <div class="field-item__line">开始时间：</div>
            <div class="field-item__value">{{ ProjectInfo.BeginTime }}</div>
          </div>
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col">
            <i class="blue-round-icon"></i>
            <div class="field-item__line">结束时间：</div>
            <div class="field-item__value">{{ ProjectInfo.EndTime }}</div>
          </div>
        </div>
        <div class="policy-info-detail-page__main__bottom">
          <!---->
          <div class="policy-info-detail-page__main__bottom__content" v-html="ProjectInfo.Content"></div>
        </div>
      </div>
      <div class="policy-info-detail-page__main__top">
        <div class="policy-info-detail-page__main__top__title">
          <span class="m-l-10 m-r-10">企业基本详情</span>
        </div>
        <div class="el-row">
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col">
            <i class="blue-round-icon"></i>
            <div class="field-item__line">企业名称：</div>
            <div class="field-item__value">{{ Enterprise.Enterprisename }}</div>
          </div>
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col">
            <i class="blue-round-icon"></i>
            <div class="field-item__line">所属区镇：</div>
            <div class="field-item__value">{{ Enterprise.ObjectTab }}</div>
          </div>
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col">
            <i class="blue-round-icon"></i>
            <div class="field-item__line">产业类型：</div>
            <div class="field-item__value">{{ Enterprise.RelatedPolicy }}</div>
          </div>
          <div class="el-col el-col-2">&nbsp;</div>
          <div class="flex el-col">
            <i class="blue-round-icon"></i>
            <div class="field-item__line">企业类型：</div>
            <div class="field-item__value">{{ Enterprise.Support }}</div>
          </div>
        </div>
      </div>
      <div class="policy-info-detail-page__main__top">
        <div class="policy-info-detail-page__main__top__title">
          <span class="m-l-10 m-r-10">资料附件</span>
        </div>
        <div>
          <div>
            <el-upload
              v-loading="loading"
              element-loading-text="努力上传中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="upload-demo"
              drag
              :http-request="uploadFile"
              action
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture"
              multiple
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
              <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>
          </div>
        </div>
      </div>
      <div class="policy-info-detail-page__main__top">
        <div class="policy-info-detail-page__main__top__title">
          <button
            type="button"
            style="line-height: 40px;padding: 0px 20px;"
            :disabled="isdisabled"
            class="el-button btn el-button--primary"
            @click="ConfirmUpload"
          >
            <span>确认上传</span>
          </button>
        </div>
      </div>
    </div>
    <div class="suspend" @click="openDialog">
      <div class="item">
        <img @dragstart.prevent src="@/img/index/customer-service.png" />
        <div>智能客服</div>
      </div>
    </div>
    <siteFooter></siteFooter>
    <el-dialog
      style="padding: 0"
      :visible.sync="dialogTableVisible"
      :modal-append-to-body="false"
      width="1200px"
    >
      <custService></custService>
    </el-dialog>
  </div>
</template>
<script>
import request from "@/api/request";
import siteFooter from "@/components/siteFooter";
import custService from "@/view/custService/custService";
export default {
  name: "PolicySearch",
  components: {
    custService: custService,
    siteFooter: siteFooter
  },
  data() {
    return {
      ProjectInfo: {
        title: "",
        Lables: "",
        Policy: "",
        Support: "",
        Content: "",
        BeginTime: "",
        EndTime: ""
      },
      Enterprise: {
        Enterprisename: "",
        ObjectTab: "",
        RelatedPolicy: "",
        Support: ""
      },
      dialogTableVisible: false,
      isshow: false,
      fileList: [],
      loading: false,
      Detailid: 0,
      isdisabled: false
    };
  },
  created() {
    this.getEnterpriseDetail();
    this.getPolicyList();
    this.Detailid = this.$route.params.id;
  },
  methods: {
    openDialog() {
      this.dialogTableVisible = true;
    },
    ConfirmUpload() {
      let arryitem = [];
      if (this.fileList.length == 0) {
        return this.$confirm("请上传资料附件");
      } else {
        for (let i = 0; i < this.fileList.length; i++) {
          arryitem.push({
            file_name: this.fileList[i].name,
            file_path: this.fileList[i].path,
            file_ext: this.fileList[i].ext,
            file_size: this.fileList[i].size
          });
        }
        if (!localStorage.getItem("USERID")) {
          this.isshow = true;
        } else {
          this.isdisabled = true;
          let url = "/api/Project/AddProjectLedger";
          let reqObj = {
            userid: localStorage.getItem("USERID"),
            projectid: this.$route.params.id,
            attachments: arryitem
          };
          // console.log(reqObj)
          request.post(url, reqObj).then(res => {
            // console.log(res);
            this.isdisabled = false;
            if (res.data.Status == 1) {
              this.$confirm("提交成功", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success"
              })
                .then(() => {
                  this.$router.push("/personalcenter/2");
                })
                .catch(() => {
                  this.$router.push("/project/projectApply");
                });
            } else {
              return this.$confirm(res.data.Msg);
            }
          });
        }
      }
    },
    handlePreview(file) {
      window.open(file.path, "_blank");
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      // console.log(fileList);
    },
    uploadFile(item) {
      let base64String = "";
      let namefile = "";
      this.loading = true;
      if (window.FileReader) {
        namefile = item.file.name;
        let reader = new FileReader();
        reader.readAsDataURL(item.file);
        reader.onload = e => {
          base64String = e.target.result;
          let url = "/api/Project/UploadByBase64";
          let reqObj = {
            filename: namefile,
            image: base64String
          };
          // console.log(reqObj)
          request.post(url, reqObj).then(res => {
            // console.log(res);
            let iconurl = "";
            if (res.data.Data.Ext == "doc" || res.data.Data.Ext == "docx") {
              iconurl =
                "https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081430144073.png";
            } else if (
              res.data.Data.Ext == "xls" ||
              res.data.Data.Ext == "xlsx"
            ) {
              iconurl =
                "https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081430335953.png";
            } else if (res.data.Data.Ext == "pdf") {
              iconurl =
                "https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081429127427.png";
            } else {
              iconurl =
                "https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081428492318.png";
            }
            //this.fileList = [...res.data.Data.map(item => { return { name: item.Name, path: item.Path, ext: item.Ext, size: item.Size, url: iconurl } })]
            this.fileList.push({
              name: res.data.Data.Name,
              path: res.data.Data.Path,
              ext: res.data.Data.Ext,
              size: res.data.Data.Size,
              url: iconurl
            });
            this.loading = false;
          });
          // console.log(this.fileList)
        };
      }
      // const formData = new FormData()
      // formData.append('processDefinition', item.file) // 传入bpmn文件
      // console.log(formData);
    },
    getPolicyList() {
      let url = "/api/Project/GetProjectDetail";
      let reqObj = {
        id: this.$route.params.id
      };
      request.post(url, reqObj).then(res => {
        // console.log(res);
        this.ProjectInfo = {
          title: res.data.Data.Title,
          Lables: res.data.Data.Lables.join("、"),
          Policy: res.data.Data.Policy,
          Support: res.data.Data.Support,
          Content: res.data.Data.Content,
          BeginTime: res.data.Data.BeginTime.replace("T", " "),
          EndTime: res.data.Data.EndTime.replace("T", " ")
        };
      });
    },
    getEnterpriseDetail() {
      let url = "/api/Project/GetQyDetail";
      let reqObj = {
        id: localStorage.getItem("USERID")
      };
      request.get(url, reqObj).then(res => {
        // console.log(res);
        if (res.data.Status == 0) {
          this.isshow = true;
        } else {
          this.Enterprise = {
            Enterprisename: res.data.Data.name,
            ObjectTab: res.data.Data.s2,
            RelatedPolicy: res.data.Data.s1,
            Support: res.data.Data.s3
          };
        }
      });
    }
  }
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  background-color: transparent !important;
  width: 950px;
  margin: 20px auto;
  z-index: 99;
}

.policy-info-detail-page__main__top {
  padding: 1.375rem 0 0.75rem 0;
  position: relative;
  background-color: #ffffff;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.policy-info-detail-page__main__top__title {
  font-size: 1.25rem;
  color: #666;
  font-weight: bold;
  margin-bottom: 1.25rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.875rem;
}

.m-r-10 {
  margin-right: 0.625rem;
}

.m-l-10 {
  margin-left: 0.625rem;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

.el-row {
  position: relative;
  box-sizing: border-box;
}

.flex.el-col {
  margin-bottom: 0.625rem;
  align-items: baseline;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-col-8 {
  width: 33.33333%;
}

.blue-round-icon {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAABWUlEQVQ4ja2VQUrDQBSGv06ilWhFuunGChYPIkiv4U68gxfwDuIJPEJ17w3caRd1XaSVYsW28uSNTIeZiVP8IYS89/9/JsmbP43T8zkRVEAb2AO2gRL4Aj6Bd2AMzELSMlBrAofAQaC3pccu0AHegFdgbWW+6T7QA4rY8j3IjVvACzCxLeNwhHCSYWhRqO73yaypPPIx0Mg0tGiofsc1PfJWvQlE37Wmlb7L/4D4VEbHJoU+cAc86rlfw2+XOocxnAHXTq/nXN9HNC2jgx3DZaR+kdA0TWQDWHQz64JCTJcJwiizLlgYf4t5uInUbxOauZhOE4QH4AoYapgM9XqQ0ExLTZtOgjSoMfExNhpfkwxRCuIzs1tzVPPB/oKl/bDW9EPf12pDw5XqxWctRCRwJRdzVyx80Yn+B34ySePJJdQgyA/tJpnbZ+cfJckueSthvNC+jGH4HwV8AyYIQ+PE3PJ4AAAAAElFTkSuQmCC)
    no-repeat;
  width: 0.9375rem;
  height: 0.9375rem;
  background-size: contain;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: sub;
}

.field-item__line {
  display: flex;
  font-size: 1rem;
  color: #666;
  width: 6.25rem;
}

.field-item__value {
  color: #333;
  font-size: 1rem;
  flex-basis: 73%;
  text-align: left;
}

.flex.el-col {
  margin-bottom: 0.625rem;
  align-items: baseline;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-col-14 {
  width: 58.33333%;
}

.policy-info-detail-page__main__top__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.25rem;
}

.collect,
.share {
  border-radius: 1.875rem;
  border-color: #a9b8cd;
  color: #2664ca;
  border: 1px solid #a9b8cd;
  padding: 10px 30px;
}

.share-comp {
  cursor: pointer;
}

.policy-info-detail-page__main__bottom {
  text-align: left;
  text-indent: 2rem;
  padding: 1.25rem 1.25rem;
}

.policy-info-detail-page__main__bottom__space {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.25rem 0;
}

.el-upload {
  display: none !important;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.el-upload-list__item:first-child {
  margin-top: 0.625rem;
}

.el-upload-list__item {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  font-size: 0.875rem;
  color: #606266;
  line-height: 1.8;
  margin-top: 0.3125rem;
  position: relative;
  box-sizing: border-box;
  border-radius: 0.25rem;
  width: 100%;
}

.el-upload-list__item-name {
  color: #606266;
  display: block;
  margin-right: 2.5rem;
  overflow: hidden;
  padding-left: 0.25rem;
  text-overflow: ellipsis;
  transition: color 0.3s;
  white-space: nowrap;
}

.el-upload-list__item-status-label {
  position: absolute;
  right: 0.3125rem;
  top: 0;
  line-height: inherit;
  display: none;
}

.el-upload-list__item-name [class^="el-icon"] {
  height: 100%;
  margin-right: 0.4375rem;
  color: #909399;
  line-height: inherit;
}

.upload-demo {
  text-align: left;
  margin-left: 10px;
}
.suspend {
  position: fixed;
  right: 1.125rem;
  bottom: 8.125rem;
  width: 5.3125rem;
  background: #ffffff;
  border-radius: 0.4375rem;
  padding: 1.25rem 0;
  z-index: 10;
}

.suspend .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suspend .item img {
  width: 1.75rem;
}

.suspend .item div {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.3125rem;
}
</style>

<style scoped>
.el-upload-list--picture .el-upload-list__item {
  overflow: hidden;
  z-index: 0;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  margin-top: 10px;
  width: 45% !important;
  padding: 10px 10px 10px 90px;
  height: 92px;
  margin: 10px 30px 10px 0px;
  display: inline-block;
}
</style>