import router from '@/router';
import axios from 'axios'
import { Message, MessageBox } from 'element-ui';

// 创建axios实例
const service = axios.create({
  baseURL: '', // api的base_url
  timeout: 50000 // 请求超时时间
})

service.interceptors.request.use(config => {
  let userId = localStorage.getItem("USERID");
  let userType = localStorage.getItem("USERTYPE");
  if (userId) {
    config.headers['USERID'] = userId
  }

  if (userType) {
    config.headers['USERTYPE'] = userType
  }
  config.headers['Content-Type'] = "application/json"
  config.headers['token'] = localStorage.getItem("TOKEN");
  return config
}, error => {
  // Do something with request error
  // console.log(error) // for debug
  Promise.reject(error)
})

service.interceptors.response.use(
  response => {
    /**
    * code为非200是抛错 可结合自己业务进行修改
    */
    const res = response.data
    // console.log(response)
    if (res.Status == 2) {
      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      // alert('非法请求，请重新登录');
      // MessageBox.alert('非法请求，请重新登录', '提示', {
      //   showConfirmButton: false,
      //   showCancelButton: false,
      // }
      // );
      if (!localStorage.getItem('MESSAGETRUE')) {
        Message({
          message: "非法请求，请重新登录",
          type: 'error',
          duration: 5 * 1000
        })
      }
      localStorage.setItem('MESSAGETRUE', true);


      localStorage.removeItem("USERID");
      localStorage.removeItem("USERTYPE");
      localStorage.removeItem("COMPANYNAME");
      localStorage.removeItem("LOGINNAME");
      localStorage.removeItem("QYID");
      localStorage.removeItem("TOKEN");
      setTimeout(() => {
        localStorage.removeItem("MESSAGETRUE");
        router.push('/login');
      }, 500);
      return response
    } else {
      return response
    }
  },
  error => {
    if (error.response && error.response.status == 400) {  //调用远程服务时，前端类型和后端不匹配

      var message = '<strong>' + error.response.data.detail + '</strong><br>'
      //异常信息需要特殊处理一下
      var errors = Object.entries(error.response.data.errors).map(([key, value]) => ({ key, value }));
      errors.forEach(item => {
        message += item.key + ":"

        item.value.forEach(dtl => {
          message += " " + dtl;
        })

        message += "<br>";
      })
      Message({
        dangerouslyUseHTMLString: true,
        message: message,
        type: 'error',
        duration: 10 * 1000
      })
    } else {
      Message({
        message: '请先启动WebApi，再刷新本页面，异常详情：' + error.message,
        type: 'error',
        duration: 10 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
