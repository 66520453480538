<template>
  <div>
    <div style="width: 950px; margin: 30px auto; background-color: #ffffff">
      <el-table :data="projects.data" style="padding: 20px">
        <el-table-column align="center" type="index" label="编号" />
        <el-table-column label="政策名称" prop="title" />
        <el-table-column align="center" label="发文单位" prop="unit" />
        <el-table-column align="center" label="申报截止时间" prop="endtime" />
        <!-- <el-table-column align="center" label="浏览量" prop="click" width="92"/> -->
        <el-table-column align="center" label="申报入口">
          <template #default="scope">
            <el-button :type="scope.row.type" @click="signUp(scope.row.id)" size="small">
              {{ scope.row.text }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="
          width: 900px;
          margin: 10px auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
        ">
        <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="projects.total"
          :page-size="pageSize" :current-page="currentPage">
        </el-pagination>
      </div>
    </div>
    <div class="suspend" @click="openDialog">
      <div class="item">
        <img @dragstart.prevent src="@/img/index/customer-service.png" />
        <div>智能客服</div>
      </div>
    </div>
    <site-footer></site-footer>
    <el-dialog style="padding: 0" :visible.sync="dialogTableVisible" :modal-append-to-body="false" width="1200px">
      <custService></custService>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/api/request";
import custService from "@/view/custService/custService";
import siteFooter from "@/components/siteFooter";
export default {
  components: {
    'custService': custService,
    siteFooter: siteFooter,
  },
  data() {
    return {
      currentPage: 1,
      dialogTableVisible: false,
      pageSize: 5,
      projects: [],
    };
  },
  created() {
    this.initData();
  },
  mounted() {
  },
  methods: {
    openDialog() {
      this.dialogTableVisible = true;
    },
    initData() {
      request
        .post("/api/Project/GetProjectList", {
          keyword: "",
          pagesize: this.pageSize,
          pageindex: this.currentPage,
        })
        .then((response) => {
          // console.log(response)
          this.projects = response.data.Data;
          this.projects.data.forEach((project) => {
            if (Date.now() > Date.parse(project.endtime)) {
              project["text"] = "已结束";
              project["type"] = "danger";
            } else if (Date.now() < Date.parse(project.begintime)) {
              project["text"] = "未开始";
              project["type"] = "success";
            } else {
              project["text"] = "立即申报";
              project["type"] = "primary";
            }
            project.endtime = project.endtime.replace(/[a-zA-Z]/g, " ");
          });
          // console.log(response.data);
        });
    },
    signUp(_id) {
      // console.log(_id);
      this.$router.push("/PolicyCalculation/policySearchDetail/" + _id);
    },
    // 当当前页发生变化时的回调
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initData(); // 重新查询数据
    },
  },
};
</script>

<style scoped>

.suspend {
  position: fixed;
  right: 1.125rem;
  bottom: 8.125rem;
  width: 5.3125rem;
  background: #ffffff;
  border-radius: 0.4375rem;
  padding: 1.25rem 0;
  z-index: 10;
}

.suspend .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suspend .item img {
  width: 1.75rem;
}

.suspend .item div {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.3125rem;
}</style>
