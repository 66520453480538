<template>
  <div>
    <demandsHeader></demandsHeader>
    <professionalsBanner></professionalsBanner>
    <div class="content" style="cursor: pointer;">
      <div class="global-max-width service-detail-page">
        <div class="products-top">
          <div class="products-top__logo">
            <img @dragstart.prevent :src="Services.url" />
          </div>
          <div class="products-top__info">
            <div class="title-box">{{ Services.CompanyName }}</div>
            <!-- <div class="info-item">
                            <div id="hostUrl" class="info-item__value"><i class="blue-round-icon"></i>
                                网址：{{ Services.Website }}
                            </div>
                        </div>
                        <div class="info-item">
                            <div id="address" class="info-item__value"><i class="blue-round-icon"></i>
                                地址：{{ Services.Address }}
                            </div>
            </div>-->
          </div>
        </div>
        <div class="flex-col">
          <div class="products-tabs">
            <div role="radiogroup">
              <label style="cursor: pointer;"
                role="radio"
                class="labelspan"
                :class="{ active: 0 == isactive }"
                @click="Skip('#page1', 0)"
              >
                <span>机构介绍</span>
              </label>
              <label
                role="radio"
                class="labelspan"
                :class="{ active: 1 == isactive }"
                @click="Skip('#page2', 1)"
                v-if="Services.Cmaf"
              >
                <span>机构资质</span>
              </label>
              <label
                role="radio"
                class="labelspan"
                :class="{ active: 2 == isactive }"
                @click="Skip('#page3', 2)"
                v-if="Services.ServingProduct"
              >
                <span>服务产品</span>
              </label>
            </div>
          </div>
          <div style="height: 389px;overflow: hidden;">
            <div class="product-detail" id="page1">
              <div class="el-ext-title el-ext-title--medium">
                <div class="el-ext-title__left">
                  <span class="el-ext-title__target">机构介绍</span>
                </div>
                <div class="el-ext-title__right">
                  <!---->
                </div>
              </div>
              <div class="spacing introducehidden" v-html="Services.Introduce"></div>
              <div class="el-ext-title el-ext-title--medium">
                <div class="el-ext-title__left">
                  <span class="el-ext-title__target">机构联系人</span>
                </div>
                <div class="el-ext-title__right">
                  <!---->
                </div>
              </div>
              <div class="spacing" style="text-align: left;">
                <p>联系人：{{ Services.Contact }}</p>
                <p>联系方式：{{ Services.ContactInformation }}</p>
              </div>
            </div>
            <div class="product-detail" id="page2">
              <div v-html="Services.Cmaf"></div>
            </div>
            <div class="product-detail" id="page3">
              <div v-html="Services.ServingProduct"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="suspend" @click="openDialog">
      <div class="item">
        <img @dragstart.prevent src="@/img/index/customer-service.png" />
        <div>智能客服</div>
      </div>
    </div>
    <siteFooter></siteFooter>
    <el-dialog
      style="padding: 0"
      :visible.sync="dialogTableVisible"
      :modal-append-to-body="false"
      width="1200px"
    >
      <custService></custService>
    </el-dialog>
  </div>
</template>
<script>
import demandsHeader from "@/components/demandsHeader";
import custService from "@/view/custService/custService";
import professionalsBanner from "@/view/professionals/components/pageBanner";
import siteFooter from "@/components/siteFooter";
import request from "@/api/request";
export default {
  name: "profesSionals",
  components: {
    demandsHeader: demandsHeader,
    custService: custService,
    professionalsBanner: professionalsBanner,
    siteFooter: siteFooter
  },
  data() {
    const arrayItem = [
      { url: "#page1", name: "机构介绍" },
      { url: "#page2", name: "机构资质" },
      { url: "#page3", name: "服务产品" }
    ];
    return {
      arrayItem: arrayItem,
      isactive: 0,
      Detailid: 0,
      dialogTableVisible: false,
      Services: {
        url: "",
        CompanyName: "",
        Website: "",
        Address: "",
        Introduce: "",
        Contact: "",
        ContactInformation: "",
        Cmaf: "",
        ServingProduct: ""
      }
    };
  },
  mounted() {
    // console.log(this.$route.params.id);
  },
  created() {
    this.Detailid = this.$route.params.id;
    this.getDetails(this.Detailid);
  },
  methods: {
    openDialog() {
      this.dialogTableVisible = true;
    },
    getDetails(id) {
      request.post("/api/Map/GetQyDetail", { id: id }).then(
        response => {
          // console.log(response)
          this.Services = {
            url: response.data.Data.image,
            CompanyName: response.data.Data.name,
            Website: response.data.Data.url,
            Address: response.data.Data.address,
            Introduce: response.data.Data.introduce,
            Contact: response.data.Data.contact,
            ContactInformation: response.data.Data.mobile,
            Cmaf: response.data.Data.aptitude,
            ServingProduct: response.data.Data.serve
          };
          return response.data;
        },
        error => {
          // console.log('错误', error.message)
        }
      );
    },
    Skip(url, index) {
      this.isactive = index;
      document.querySelector(url).scrollIntoView(true);
    }
  }
};
</script>
<style scoped>
@import url("../../../public/layui/css/layui.css");

* {
  margin: 0;
  padding: 0;
}

.active {
  color: #ffffff;
  background-color: #028dfb;
}

.content {
  background-color: #ffffff;
  width: 950px;
  margin: 20px auto;
  z-index: 99;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.service-detail-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  padding: 1.375rem;
  border-radius: 0.3125rem;
  box-shadow: 0 0.1875rem 0.4375rem 0 rgba(0, 0, 0, 0.11);
}

.products-top {
  display: flex;
  border-bottom: 0.0625rem solid #eee;
}

.products-top__logo {
  width: 13rem;
  height: 9.6875rem;
  margin-bottom: 1.25rem;
}

.products-top__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: inline-block;
  border: none;
  vertical-align: middle;
}

.products-top__info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.service-detail-page .products-top__info .title-box {
  font-size: 1.25rem;
  text-align: left;
  font-weight: bold;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  white-space: nowrap;
  margin: 0.625rem 0 0.625rem;
}

.info-item {
  color: #666;
  font-size: 0.875rem;
  line-height: 1.375rem;
  width: 95%;
  margin-bottom: 0.3125rem;
  position: relative;
}

.service-detail-page .products-top__info .info-item .zhankai {
  position: absolute;
  right: -1.25rem;
  bottom: 0;
  cursor: pointer;
}

.service-detail-page .products-top__info .info-item__value {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blue-round-icon {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAABWUlEQVQ4ja2VQUrDQBSGv06ilWhFuunGChYPIkiv4U68gxfwDuIJPEJ17w3caRd1XaSVYsW28uSNTIeZiVP8IYS89/9/JsmbP43T8zkRVEAb2AO2gRL4Aj6Bd2AMzELSMlBrAofAQaC3pccu0AHegFdgbWW+6T7QA4rY8j3IjVvACzCxLeNwhHCSYWhRqO73yaypPPIx0Mg0tGiofsc1PfJWvQlE37Wmlb7L/4D4VEbHJoU+cAc86rlfw2+XOocxnAHXTq/nXN9HNC2jgx3DZaR+kdA0TWQDWHQz64JCTJcJwiizLlgYf4t5uInUbxOauZhOE4QH4AoYapgM9XqQ0ExLTZtOgjSoMfExNhpfkwxRCuIzs1tzVPPB/oKl/bDW9EPf12pDw5XqxWctRCRwJRdzVyx80Yn+B34ySePJJdQgyA/tJpnbZ+cfJckueSthvNC+jGH4HwV8AyYIQ+PE3PJ4AAAAAElFTkSuQmCC)
    no-repeat;
  width: 0.9375rem;
  height: 0.9375rem;
  background-size: contain;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: sub;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.products-tabs {
  text-align: center;
  margin: 1.25rem 0;
}

.el-radio-group {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;
}

.el-radio-button {
  position: relative;
  display: inline-block;
  outline: none;
}

.labelspan {
  padding: 10px;
  border: 1px solid #028dfb;
}

.labelspan:first-child {
  border-radius: 5px 0px 0px 5px;
}

.labelspan:last-child {
  border-radius: 0px 5px 5px 0px;
}

.product-detail {
  max-height: 389px;
  overflow-y: auto;
  height: 389px;
  color: #333;
  font-size: 0.875rem;
}

.el-ext-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-ext-title__left {
  position: relative;
  padding-left: 0.875rem;
  display: flex;
  align-items: center;
}

.service-detail-page .product-detail .el-ext-title__left::before {
  margin-top: -0.5rem;
  width: 0.25rem;
  height: 1.125rem;
  border-radius: 0;
}

.el-ext-title__left::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -0.75rem;
  width: 0.4375rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  background-color: #409eff;
}

.el-ext-title__target {
  color: #048dfb;
  font-weight: 600;
  font-size: 1rem;
}

.el-ext-title__target {
  font-size: 1.5rem;
  color: #333;
  font-weight: 700;
}

.el-ext-title__right {
  font-size: 0.75rem;
  color: #999;
}

.el-ext-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-ext-title__left {
  position: relative;
  padding-left: 0.875rem;
  display: flex;
  align-items: center;
}

.el-ext-title__target {
  color: #048dfb;
  font-weight: 600;
  font-size: 1rem;
}

.product-detail {
  color: #333;
  font-size: 0.875rem;
}

.product-detail {
  color: #333;
  font-size: 0.875rem;
}

.product-detail p {
  margin: 10px auto;
}

.spacing {
  margin: 15px auto;
}

.introducehidden {
  text-indent: 2rem;
  text-align: left;
  max-height: 202px;
  overflow-y: auto;
}
.suspend {
  position: fixed;
  right: 1.125rem;
  bottom: 8.125rem;
  width: 5.3125rem;
  background: #ffffff;
  border-radius: 0.4375rem;
  padding: 1.25rem 0;
  z-index: 10;
}

.suspend .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suspend .item img {
  width: 1.75rem;
}

.suspend .item div {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.3125rem;
}
</style>