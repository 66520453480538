<template>
  <div>
    <demandsHeader></demandsHeader>
    <thousandsPageBanner></thousandsPageBanner>
    <!-- <div class="top-header"></div> -->
    <div class="content1">
      <div class="content-all">
        <div @click="thousands">
          <img @dragstart.prevent src="../../images/EnterpriseUsers.jpg" alt="" srcset="" style="cursor: pointer;width: 350px;">
        </div>
        <div @click="thousands1">
          <img @dragstart.prevent src="../../images/ServiceEnterprise.jpg" alt="" srcset="" style="cursor: pointer;width: 350px;">
        </div>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import demandsHeader from "@/components/demandsHeader";
import thousandsPageBanner from "@/view/thousands/thousandsPageBanner";
import siteFooter from "@/components/siteFooter";
export default {
  components: {
    demandsHeader: demandsHeader,
    thousandsPageBanner:thousandsPageBanner,
    siteFooter: siteFooter,
  },
  methods: {
    
    thousands() {
      window.open('https://q.qdqrqq.com/thousands/login.aspx', '_blank')
    },
    thousands1() {
      window.open('https://q.qdqrqq.com/thousands_shilingdao/login.aspx', '_blank')
    },
  },
};
</script>

<style scoped>
.top-header {
  height: 15.625rem;
  background: url(../../images/zjtop.png);
  margin-bottom: 1.5rem;
  width: 100%;
}

.content1 {
  z-index: 9999999;
  margin-bottom: 0.9375rem;
  width: 75rem;
  display: block;
  box-sizing: border-box;
  margin: 0 auto;
}
.content-all {
  margin: 2.125rem auto;
  min-height: 6.25rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.content-p {
  width: 20rem;
  font-size: 2rem;
  color: #fff;
  height: 20rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0.9375rem;
  text-align: center;
}
.content-button {
  cursor: pointer;
  width: 6.75rem;
  height: 2.5rem;
  border-radius: 2.75rem;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: #fff;
  margin-left: 105px;
  margin-top: 20px;
}
</style>