<template>
  <div class="activity-page">
    <demands-header></demands-header>
    <activity-page-banner></activity-page-banner>
    <div class="container" style="margin-top: 20px; margin-bottom: 20px">
      <div class="left-section">
        <el-calendar v-model="value" class="my-calendar">
          <template slot="dateCell" slot-scope="{date, data}">
            <div style="width:100%;height: 100%;">
              <div
                v-for="(item, index) in AllActiveTime"
                :key="index"
                style="width: 0;height: 0px;"
              >
                <el-badge
                  is-dot
                  class="badgeitem"
                  type="info"
                  v-if="data.day == formatdaytimeslice(item.begin_time) && formatdaytimeslice(item.begin_time) < formateDate()"
                  :value="data.day.split('-').slice(2).join('-')"
                ></el-badge>
                <el-badge
                  is-dot
                  class="badgeitem"
                  v-if="data.day == formatdaytimeslice(item.begin_time) && formatdaytimeslice(item.begin_time) >= formateDate()"
                  :value="data.day.split('-').slice(2).join('-')"
                ></el-badge>
              </div>
              <div class="spandate">{{ data.day.split('-').slice(2).join('-') }}</div>
            </div>
          </template>
        </el-calendar>
        <div class="recent_activities">
          <span>近期活动</span>
        </div>
        <div
          v-for="(item, index) in recentActivities"
          :key="index"
          @click="activityInfo(item.id, currentStatus[index])"
        >
          <div class="activity-info">
            <!-- <img @dragstart.prevent src="../../images/activity-banner.png" alt="activity_image" /> -->
            <img @dragstart.prevent :src="item.imgUrl" alt="activity_image" />
            <div class="info-text">{{item.title}}</div>
          </div>
        </div>
      </div>
      <div class="right-section">
        <div class="status-box">
          <div style="margin-bottom: 20px" v-if="date">
            <el-tag closable :disable-transitions="false" @close="handleClose">当前选择：{{ date }}</el-tag>
          </div>
          <div style="display: inline">状态：</div>
          <el-button
            v-for="(btn, index) in buttons"
            :key="index"
            :type="btn.type"
            @click="chooseStatus(index)"
          >{{ btn.text }}</el-button>
        </div>
        <div class="activity-list">
          <div v-if="activities.length === 0">
            <el-empty description="暂无数据"></el-empty>
          </div>
          <div
            class="activity-item"
            v-for="(activity, index) in activities"
            :key="activity.id"
            @click="activityInfo(activity.id, currentStatus[index])"
            style="font-size: 16px"
          >
            <div style="display: flex;justify-content: space-between">
              <div style="width: 450px">
                <div
                  class="title"
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >{{ activity.title }}</div>
                <div
                  class="time"
                  style="margin-top: 5px; color: #6d6d6d"
                >活动时间：{{ activity.begin_time }} 至 {{ activity.end_time }}</div>
                <div
                  class="address"
                  style="margin-top: 5px; color: #6d6d6d"
                >活动地址：{{ activity.address }}</div>
                <!-- <div class="address" style="margin-top: 5px; color: #6d6d6d">
                  浏览量：{{ activity.click }}
                </div>-->
              </div>
              <div class="status-tag">
                <el-tag type="warning" v-if="currentStatus[index] === 0">未开始</el-tag>
                <el-tag type="success" v-else-if="currentStatus[index] === 1">进行中</el-tag>
                <el-tag type="danger" v-else>已结束</el-tag>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div
            style="
          width: 100%;
          margin: 20px auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
        "
          >
            <el-pagination
              background
              layout="prev, pager, next"
              :pageSize="pageSize"
              :total="total"
              @current-change="handleCurrentChangeactivity"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="suspend" @click="openDialog">
      <div class="item">
        <img @dragstart.prevent src="@/img/index/customer-service.png" />
        <div>智能客服</div>
      </div>
    </div>
    <site-footer></site-footer>
    <el-dialog
      style="padding: 0"
      :visible.sync="dialogTableVisible"
      :modal-append-to-body="false"
      width="1200px"
    >
      <custService></custService>
    </el-dialog>
  </div>
</template>

<script>
import request from "../../api/request";
import demandsHeader from "@/components/demandsHeader";
import custService from "@/view/custService/custService";
import siteFooter from "@/components/siteFooter";
import activityPageBanner from "@/components/activityPageBanner";
export default {
  components: {
    demandsHeader: demandsHeader,
    custService: custService,
    siteFooter: siteFooter,
    activityPageBanner: activityPageBanner
  },
  data() {
    return {
      value: new Date(),
      status: 0,
      date: "",
      pageSize: 6,
      dialogTableVisible: false,
      total: 0,
      activities: [],
      recentActivities: [],
      AllActiveTime: [],
      currentStatus: [],
      buttons: [
        { text: "不限", type: "primary" },
        { text: "未开始", type: "info" },
        { text: "进行中", type: "info" },
        { text: "已结束", type: "info" }
      ],
      activeIndex: 0 // 默认选中第一个按钮
    };
  },
  watch: {
    value: function() {
      var date = this.formateDate();
      this.chooseDate(date); //  调用接口，把拼接好的参数传到后台
    }
  },
  computed: {},
  mounted() {
    this.initData(1);
  },
  methods: {
    openDialog() {
      this.dialogTableVisible = true;
    },
    handleClose() {
      this.date = "";
      this.getActivityList();
    },
    formateDate() {
      var year = this.value.getFullYear();
      var month = this.value.getMonth() + 1;
      var date = this.value.getDate();
      if (date >= 1 && date <= 9) {
        //日如果小于10就补个0
        date = "0" + date;
      }
      if (month >= 1 && month <= 9) {
        //月如果小于10就补个0
        month = "0" + month;
      }
      // console.log(year + "-" + month + "-" + date); // 打印出日历选中了哪年哪月
      return year + "-" + month + "-" + date;
    },
    formatdaytimeslice(x) {
      const y = new Date(x);
      var year = y.getFullYear();
      var month = y.getMonth() + 1;
      var date = y.getDate();
      if (date >= 1 && date <= 9) {
        //日如果小于10就补个0
        date = "0" + date;
      }
      if (month >= 1 && month <= 9) {
        //月如果小于10就补个0
        month = "0" + month;
      }
      // console.log(year + "-" + month + "-" + date); // 打印出日历选中了哪年哪月
      return year + "-" + month + "-" + date;
    },
    formatDayTime(v) {
      const date = new Date(v);
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${date
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
    },
    initData(pageIndex) {
      request
        .post("/api/Activity/GetActivityList", {
          status: 0,
          date: "",
          pageSize: this.pageSize,
          pageIndex: pageIndex
        })
        .then(response => {
          // console.log(response);
          // 请求成功时处理数据
          if (response.data.Data && response.data.Data.data) {
            this.activities = [...response.data.Data.data];
            console.log(pageIndex)
            if (pageIndex == 1) {
              this.recentActivities = [
                ...response.data.Data.data.filter(x => x.imgUrl != "")
              ].slice(0, 2);
            }
            this.total = response.data.Data.total;
            // console.log(this.total);
            this.formateList();
            // console.log(this.activities);
            this.AllActiveTime = this.activities;
            // console.log(this.AllActiveTime)
          }
        })
        .catch(error => {
          // 请求失败时的处理
          console.error("Error fetching data:", error);
        });
    },
    //页码切换
    handleCurrentChangeactivity(val) {
      // console.log(this.status)
      // console.log(this.currentStatus)
      this.currentStatus = [];
      if (this.status == 0) {
        this.initData(val);
      } else {
        this.getActivityList(val);
      }
    },
    formateList() {
      var index = 0;
      this.activities.forEach(activity => {
        if (Date.now() < Date.parse(activity.begin_time)) {
          this.currentStatus.push(0);
        } else if (Date.now() > Date.parse(activity.end_time)) {
          this.currentStatus.push(2);
        } else {
          this.currentStatus.push(1);
        }
        activity.begin_time = activity.begin_time.replace(/[a-zA-Z]/g, " ");
        activity.end_time = activity.end_time.replace(/[a-zA-Z]/g, " ");
        activity.activitybegin_time = activity.activitybegin_time.replace(
          /[a-zA-Z]/g,
          " "
        );
        activity.activityend_time = activity.activityend_time.replace(
          /[a-zA-Z]/g,
          " "
        );
      });
    },
    prevMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    },
    currentMonth() {
      this.currentDate = new Date();
    },
    nextMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    },

    activityInfo(_id, _index) {
      // console.log(_id, _index);
      this.$router.push({
        path: "/activityInfo",
        query: {
          id: _id,
          index: _index
        }
      });
    },

    chooseStatus(index) {
      // 更新按钮状态
      this.buttons[this.activeIndex].type = "info"; // 将之前选中的按钮样式重置
      this.activeIndex = index;
      this.buttons[index].type = "primary"; // 设置新选中的按钮样式
      // 发起查询请求
      this.status = index;
      this.activities = [];
      this.currentStatus = [];
      if (this.date == "" && this.status == 0) {
        this.initData();
      } else {
        this.getActivityList();
      }
    },
    chooseDate(date) {
      // console.log(date);
      (this.date = date), this.getActivityList();
    },
    getActivityList(pageIndex) {
      request
        .post("/api/Activity/GetActivityList", {
          date: this.date,
          status: this.status,
          pageSize: this.pageSize,
          pageIndex: pageIndex
        })
        .then(response => {
          // 请求成功时处理数据
          // console.log(response.data);
          if (response.data.Data && response.data.Data.data) {
            this.activities = [...response.data.Data.data];
            this.total = response.data.Data.total;
            this.currentStatus = [];
            this.formateList();
          }
        })
        .catch(error => {
          // 请求失败时的处理
          console.error("Error fetching data:", error);
        });
    }
  }
};
</script>

<style scoped>
.container {
  width: 1000px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.left-section {
  flex: 1.5;
  background-color: white;
  margin-right: 20px;
  justify-content: center;
}

.my-calendar .el-calendar-table .el-calendar-day {
  width: 10px;
  /* 调整单元格宽度 */
  height: 20px;
  /* 调整单元格高度 */
  line-height: 20px;
  text-align: center;
  background-color: transparent;
  /* 清除默认的背景色 */
}

.right-section {
  flex: 2.5;
  background-color: #f7f7f7;
  justify-content: center;
}

.date-picker {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.activity-info {
  width: 90%;
  margin: 0px auto 20px auto;
  text-align: center;
}

.activity-info img {
  width: 330px;
  height: 160px;
  object-fit: fill;
}

.info-text {
  width: 330px;
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activity-list {
  border-radius: 5px;
  padding: 10px;
}

.activity-item {
  height: 80px;
  margin-bottom: 10px;
  background-color: white;
  padding: 15px 15px 15px 15px;
  cursor: pointer;
}

.title {
  font-weight: bold;
}

.status-box {
  margin: 20px;
}

.status-tag {
  /* display: flex; */
  /* 使用 Flexbox 布局 */
  /* justify-content: flex-end; */
  /* 将子元素向右对齐 */
}

.recent_activities {
  width: 100%;
  text-align: center;
  margin: -10px auto 20px auto;
}
.recent_activities span {
  padding-bottom: 3px;
  border-bottom: 5px solid #4084ff;
}
</style>
<style>
.el-calendar-table .el-calendar-day {
  box-sizing: border-box;
  padding: 8px;
  height: 45px;
}

.badgeitem {
  margin-top: -15px;
  margin-left: 30px;
}

.is-dot {
  height: 4px !important;
  width: 4px !important;
}
.suspend {
  position: fixed;
  right: 1.125rem;
  bottom: 8.125rem;
  width: 5.3125rem;
  background: #ffffff;
  border-radius: 0.4375rem;
  padding: 1.25rem 0;
  z-index: 10;
}

.suspend .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suspend .item img {
  width: 1.75rem;
}

.suspend .item div {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.3125rem;
}
</style>