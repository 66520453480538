<template>
  <div>
    <div class="content" style="margin: 20px auto;text-align: center;background-color: #ffffff;">
      <div class="policy-page-computed-model" @click="rapidActuarial" data-v-0d594a84>
        <div class="el-image" data-v-0d594a84>
          <img @dragstart.prevent src="../../img/policy/left.964d0ad.png" alt="为您量身定制个性化政策" class="el-image__inner">
          <!-- <div class="el-image__placeholder"></div> -->
        </div> <button type="button" class="el-button btn el-button--primary"
          data-v-0d594a84><!----><!----><span>快速精算</span></button>
      </div>
    </div>
    <div class="suspend" @click="openDialog">
      <div class="item">
        <img @dragstart.prevent src="@/img/index/customer-service.png" />
        <div>智能客服</div>
      </div>
    </div>
    <siteFooter></siteFooter>
    <el-dialog style="padding: 0" :visible.sync="dialogTableVisible" :modal-append-to-body="false" width="1200px">
      <custService></custService>
    </el-dialog>
  </div>
</template>
<script>
import siteFooter from "@/components/siteFooter";
import custService from "@/view/custService/custService";
export default {
  name: "PolicySearch",
  components: {
    'custService': custService,
    'siteFooter': siteFooter,
  },
  data() {
    return {
      input: "",
      select: "",
      dialogTableVisible: false,
    };
  },
  methods: {
    openDialog() {
      this.dialogTableVisible = true;
    },
    rapidActuarial() {
      this.$router.push('/PolicyCalculation/rapidActuarial')
    },
    declareToday() {
      this.$router.push('/declareToday')
    },
    policySearch() {
      this.$router.push('/policySearch')
    },
    policyInterpretation() {
      this.$router.push('/policyInterpretation')
    },
    policySubscription() {
      this.$router.push('/policySubscription')
    }
  },
};
</script>
<style scoped>

.suspend {
  position: fixed;
  right: 1.125rem;
  bottom: 8.125rem;
  width: 5.3125rem;
  background: #ffffff;
  border-radius: 0.4375rem;
  padding: 1.25rem 0;
  z-index: 10;
}

.suspend .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suspend .item img {
  width: 1.75rem;
}

.suspend .item div {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.3125rem;
}
</style>
